import { ImageList, ImageListItem, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BlogCategoryDelete from "./BlogCategoryDelete";

const BlogCategoryTable = ({ item, id, setDeleteBlogCategoryId }) => {
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

  return (
    <div className="pb-4 bg-gray-100 shadow-lg border-t-4 mt-6 p-1 border-black">
      {" "}
      <Typography
        className=""
        variant="body"
        fontSize={"12px"}
        gutterBottom
        sx={{ marginTop: "4px", marginLeft: "10px" }}
      >
        <div className="grid grid-cols-2 ">
          <div>
            <b>english name: </b>
            {item?.category?.name?.english}
            <br />
            <b>amharic name: </b>
            {item?.category?.name?.amharic}
            <br />
          </div>
          <div className="flex w-full">
            <div className="justify-end">
              {" "}
              <b>created at:</b>
              {item?.category?.created_at}
              <br />
              <b>updated at:</b>
              {item?.category?.updated_at}
              <br />
            </div>
          </div>
        </div>
        <b>category image:</b>
      </Typography>
      <ImageList
        className="ml-4  mr-6  border-2 shadow-lg rounded"
        sx={{ width: 450, height: 250 }}
        cols={3}
        rowHeight={200}
      >
        {item?.category?.category_image.map((item) => {
          return (
            <>
              <ImageListItem className="p-2" key={item}>
                <img
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            </>
          );
        })}
      </ImageList>
      <div className="w-[10%]  flex justify-end pr-4">
        {showDeleteCategoryModal ? (
          <>
            <BlogCategoryDelete
              blogCategory={item}
              setShowDeleteCategoryModal={setShowDeleteCategoryModal}
              setDeleteBlogCategoryId={setDeleteBlogCategoryId}
            />
          </>
        ) : null}
        <button
          className=""
          onClick={() => {
            setShowDeleteCategoryModal(true);
          }}
        >
          <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default BlogCategoryTable;
