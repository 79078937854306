import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { AudioCard, VideoCard } from "material-ui-player";
import { BiRun } from "react-icons/bi";
import screenfull from "screenfull";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import ExpertReviewStatusUpdate from "./ExpertReviewStatusUpdate";

const ExerciseMediaTable = ({
  expertReview,
  id,
  setEditExpertStatusId,
  setEditStatId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showMediaModal, setShowMediaModal] = useState(false);
  const navigate = useNavigate();

  return (
    <tr key={id} class="bg-white border-b flex flex-row">
      <div>
        <div class="px-2 pl-14 py-4">
          <Card sx={{ width: 200, height: 164 }} raised={true}>
            <p class="pt-2 pl-2">
              <b>User:</b>
            </p>
            <div className="p-4">
              <p>Name: {expertReview?.user?.name}</p>
              <p>Phone: {expertReview?.user?.phone}</p>
              <p>Email: {expertReview?.user?.email}</p>
              <p>Role: {expertReview?.user?.role?.name}</p>
            </div>
          </Card>
        </div>
      </div>
      <div class="px-2 py-4">
        <Card >
          <p class=" pt-2 pl-2">
            <b>Comment:</b>
          </p>
          <div className="pl-6 pt-1">
            {" "}
            <p> {expertReview?.comment}</p>
          </div>
          <div className="p-2 flex flex-row">
            <div>
              <br /> <p>Rating: {expertReview?.rating}</p>
              <div>
                <p>
                  Status: {expertReview?.status == 0 ? "Blocked" : "Active"}
                  <button
                    onClick={() => {
                      setShowEditModal(true);
                      setEditExpertStatusId(expertReview?.id);
                    }}
                  >
                    <RiEdit2Fill className="text-gray-500 ml-4   text-2xl" />
                  </button>
                </p>
              </div>
              <div>
                {showEditModal ? (
                  <>
                    <ExpertReviewStatusUpdate
                      showEditModal={showEditModal}
                      setShowEditModal={setShowEditModal}
                      expertStatus={expertReview}
                      id={id}
                      setEditExpertStatusId={setEditExpertStatusId}
                      setEditStatId={setEditStatId}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
        <div className="p-2 text-sm flex justify-end">
          {" "}
          <p>Created: {expertReview?.created_at}</p>
        </div>
      </div>
    </tr>
  );
};

export default ExerciseMediaTable;
