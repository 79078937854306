import { ImageList, Select } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/auth";

import { useFormik } from "formik";
import * as Yup from "yup";

const ExercisesEdit = ({
  showEditModal,
  setShowEditModal,
  exercise,
  id,
  setEditExeId,

  setEditExerciseId,
}) => {
  const [englishExerciseName, setEnglishExerciseName] = useState(
    exercise?.title?.english
  );
  const [amharicExerciseName, setAmharicExerciseName] = useState(
    exercise?.title?.amharic
  );
  const [descriptionEnglish, setDescriptionEnglish] = useState(
    exercise?.description?.english
  );
  const [descriptionAmharic, setDescriptionAmharic] = useState(
    exercise?.description?.amharic
  );
  const [duration, setDuration] = useState(exercise?.duration);
  const [exerciseTrisemester, setExerciseTrisemester] = useState(null);


  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editExerciseMutation = useMutation(
    async (editExercise) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `admin/exercises/${exercise?.id}`,
        formData,
        config
      )
  );

  const editExerciseHandler = async (newData) => {
    try {
      formData.append("titleEnglish", englishExerciseName);
      formData.append("titleAmharic", amharicExerciseName);
      formData.append("descriptionEnglish", descriptionEnglish);
      formData.append("descriptionAmharic", descriptionAmharic);
      formData.append("duration", duration);
      formData.append("_method", "patch");


      editExerciseMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditExeId(null);
            toast.success("Edit success");
            setShowEditModal(false);
          },
          onError: (err) => {
            toast.error(err?.response?.data?.message ?? "Edit failed");
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };


  const TrisemesterData = useQuery(
    ["TrisemesterDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/trimesters`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
   console.log(TrisemesterData.data.data.data[0])

  const tempObj = [];
  TrisemesterData?.data?.data?.data.map((item) => {
    tempObj.push({ id: item.id, name: item.name.english });
  });
  const myDefault = [null];
  const trimesterOptions = [...tempObj];
  console.log(trimesterOptions)

  const formik = useFormik({
    initialValues: {
      englishName: exercise?.title?.english,
      amharicName: exercise?.title?.amharic,
      englishDesc: exercise?.description?.english,
      amharicDesc: exercise?.description?.amharic,
      durationEdit: exercise?.duration,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDesc: Yup.string().required("English Description is required"),
      amharicDesc: Yup.string().required("Amharic Description is required"),
      durationEdit: Yup.string().required("Duration is required"),
    }),
    onSubmit: () => {
      editExerciseHandler();
      setEditExeId(1);
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[80%]  h-[80%] my-6 mx-auto ">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Edit Exercise</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowEditModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
              >
                <div className="relative p-6 ">
                  <form className=" px-2 pt-6 pb-8 w-full">
                    <div className="flex flex-row px-8 w-full">
                      <div className="flex flex-col  px-8 w-full">
                        <div>
                          <label className="block text-black text-sm font-base mb-1">
                            English Exercise Name
                          </label>
                          <input
                            id="englishName"
                            value={formik.values.englishName}
                            type="text"
                            className=" shadow appearance-none border rounded w-80 py-2 px-1 text-black"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setEnglishExerciseName(e.target.value);
                            }}
                          />
                          {formik.touched.englishName &&
                          formik.errors.englishName ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.englishName}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="block text-black text-sm font-base mb-1">
                            Amharic Exercise Name
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                            id="amharicName"
                            value={formik.values.amharicName}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setAmharicExerciseName(e.target.value);
                            }}
                          />
                          {formik.touched.amharicName &&
                          formik.errors.amharicName ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.amharicName}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="block text-black text-sm font-base mb-1">
                            English Description
                          </label>
                          <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                            id="englishDesc"
                            value={formik.values.englishDesc}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setDescriptionEnglish(e.target.value);
                            }}
                          />

                          {formik.touched.englishDesc &&
                          formik.errors.englishDesc ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.englishDesc}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="block text-black text-sm font-base mb-1">
                            Amharic Description
                          </label>
                          <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                            value={formik.values.amharicDesc}
                            id="amharicDesc"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setDescriptionAmharic(e.target.value);
                            }}
                          />
                          {formik.touched.amharicDesc &&
                          formik.errors.amharicDesc ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.amharicDesc}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label className="block text-black text-sm font-base mb-1">
                            Duration
                          </label>
                          <input
                            id="durationEdit"
                            value={formik.values.durationEdit}
                            className="shadow appearance-none border rounded w-80 py-2 px-1 text-black"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setDuration(e.target.value);
                            }}
                          />
                          {formik.touched.durationEdit &&
                          formik.errors.durationEdit ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.durationEdit}
                            </div>
                          ) : null}
                        </div>
                        <div className=" ">
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      <br />
                      Select trimesters
                    </label>
                    <Select
                      isMulti={true}
                      styles={{
                        menuPortal: (base) => ({
                          zIndex: 999999,
                        }),
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      defaultValue={myDefault}
                      options={trimesterOptions}
                      onChange={(newSelection) => {
                        console.log({ newSelection });
                        // handleChange();

                        setExerciseTrisemester(newSelection);
                      }}
                    />
                  </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editExerciseMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editExerciseMutation.isLoading
                      ? "saving.."
                      : "Save Changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      <ToastContainer />
    </div>
  );
};

export default ExercisesEdit;
