import React from 'react';

const TimeConverter = ({ time }) => {
  const convertTo12HourFormat = time => {
    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);

    const convertedTime = date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });

    return convertedTime;
  };

  const convertedTime = convertTo12HourFormat(time);

  return <p>{convertedTime}</p>;
};

export default TimeConverter;