import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { AudioCard, VideoCard } from "material-ui-player";
import { BiRun } from "react-icons/bi";
import screenfull from "screenfull";
import { useNavigate } from "react-router-dom";
import ExerciseMediaDelete from "./ExerciseMediaDelete";
import ExerciseMediaEdit from "./ExerciseMediaEdit";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";

const ExerciseMediaTable = ({
  exercise,
  id,
  setDeleteExerciseMediaId,
  setEditExerciseMediaId,
  setViewExerciseMediaId,
  setEditMedId,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showMediaModal, setShowMediaModal] = useState(false);
  const navigate = useNavigate();

  return (
    <tr key={id} class="bg-white border-b flex flex-row">
     <div><div className="text-xl"> <div class="px-6 py-4">
        <p><b>Caption:</b> {exercise?.caption}</p>
      </div>
      <div class="px-6 py-4">
        <p><b>Order: </b>{exercise?.order}</p>
      </div></div>
      <div class="px-6 py-4">
      <p class=" pb-4"><b>Image:</b></p>
        <Card sx={{ width: 200, height: 164 }} raised={true}>
          <ImageList cols={1} rowHeight={164}>
            {exercise?.exercise_image?.map((item) => (
              <ImageListItem key={item.uuid}>
                <img
                  src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.uuid}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Card>
      </div>
      </div>
      <div class="px-6 py-4">
      <p class=" pb-4"><b>Video:</b></p>{!exercise.exercise_video ?
        (<>No</>):(<>{exercise?.exercise_video?.map((item) => (
          <Card sx={{ width:"200", height: "164" }} raised={true}>
            <ReactPlayer url={item.url} controls />
          </Card>

          /*           <VideoCard src={"https://www.youtube.com/watch?v=sY8lYexr72U"} />
           */
        ))}</>) }
      </div>{" "}
      <td class="px-6 py-4 flex justify-end ">
        <div className="">
          <div>
            {showDeleteModal ? (
              <>
                <ExerciseMediaDelete
                  exercise={exercise}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeleteExerciseMediaId={setDeleteExerciseMediaId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showEditModal ? (
              <>
                <ExerciseMediaEdit
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  exercise={exercise}
                  id={id}
                  setEditExerciseMediaId={setEditExerciseMediaId}
                  setEditMedId={setEditMedId}
                />
              </>
            ) : null}
          </div>
          <div className="">
            {/*   <button
              onClick={() => {
                setShowViewModal(true);
                setViewExerciseMediaId(exercise?.id);
              }}
            >
              <AiFillEye
                onClick={() => navigate(`/exercises/${exercise.id}`)}
                className="text-gray-500 mr-2 text-2xl"
              />
            </button> */}

            <button
              onClick={() => {
                setShowEditModal(true);
                setEditExerciseMediaId(exercise?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteExerciseMediaId(exercise?.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ExerciseMediaTable;
