import React, { useState } from "react";

import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { useAuth } from "../context/auth";
import { ImageList } from "@mui/material";
import { ColorTranslator } from "colortranslator";
const DeleteCategoryModal = ({
  category,
  id,
  setDeleteCategoryId,
  setEditCategoryId,
  setEditBlogId,
}) => {
  const [englishName, setEnglishName] = useState();
  const [amharicName, setAmharicName] = useState();
  const [image, setImage] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCategoryIds, setEditCategoryIds] = useState();
  const [oldColor, setOldColor] = useState(category.color);
  const [type, setType] = useState(
    category.type == "Blog" ? 3 : category.type == "Forum" ? 1 : category.type == "Tip" ? 4: null
  );
  const [selecteds, setSelecteds] = useState();
  const [categoryImage, setCategoryImage] = useState(category?.category_image);

  var oldColorSub = `#${oldColor.substring(4)}`;
  const [color, setColor] = useState("#fff");
  var newColor = `0xff${ColorTranslator.toHEX(color).substring(1)}`;

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const DeleteCategoryMutation = useMutation(
    async (deleteCategory) =>
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL +
          `admin/categories/${deleteCategory?.id}`,
        {
          headers,
          enabled: !!token,
        }
      ),
    {
      retry: false,
    }
  );

  const DeleteCategoryHandler = async (newData) => {
    try {
      DeleteCategoryMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: (responseData) => {
            setDeleteCategoryId(null);
            toast({
              title: "Category deleted successfully",
              status: "success",
           
            });
          },
          onError: (responseData) => {
            toast({
              title:
                responseData?.response?.data?.message ||
                "Category not deleted!",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editCategoryMutation = useMutation(
    async (editCategory) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/categories/${editCategory?.id}`,
        formData,
        config
      )
  );

  const editCategoryHandler = async (newData) => {
    console.log({ englishName });

    // const config = {
    //   headers,
    // };
    // axios.patch(
    //   process.env.REACT_APP_BACKEND_URL + `admin/categories/${editCategoryIds}`,
    //   params,
    //   config
    // );
    //editCategoryMutation.mutate()
    try {
      formData.append("nameEnglish", englishName);
      formData.append("nameAmharic", amharicName);
      formData.append("type", type);
      formData.append("color", newColor);
      formData.append("category_photo", categoryImage);
      formData.append("_method", "patch");

      editCategoryMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditBlogId(null);
            toast.success("success", {});
            setShowEditModal(false);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      englishName: category?.name?.english,
      amharicName: category?.name?.amharic,
      color: oldColorSub,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      color: Yup.string().required("color is required"),
    }),
    onSubmit: (values) => {
      setEditCategoryIds(category?.id);
      setEditCategoryId(category?.id);
      editCategoryHandler(category.id);
      setEditBlogId(1);
    },
  });

  const handleSelect = (e) => {
    console.log("Category selected");
    console.log(e.target.value);
    setType(e.target.value);
  };

  return (
    <tr key={id} class="bg-white border-b">
      <>
        <td class="px-6 py-4">
          <p>{category?.name?.english}</p>
        </td>
        <td class="px-6 py-4">
          <p>{category?.name?.amharic}</p>
        </td>
        <td class="px-6 py-4">
          <p>{category?.type}</p>
        </td>

        <td class="px-6 py-4">
          <div className="flex justify-end">
            <div>
              {showDeleteModal ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative  w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h3 className="text-3xl font-semibold">
                            Delete Blog Category
                          </h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <p className="text-2xl text-bold">
                            Are you sure you want to delete?
                          </p>
                          <br />
                          <p>English name:</p>
                          <p>{category?.name?.english}</p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                          <button
                            className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowDeleteModal(false)}
                          >
                            Close
                          </button>
                          <button
                            className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              setShowDeleteModal(false);
                              DeleteCategoryHandler(category?.id);
                              //window.location.reload(false);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </div>

            <div>
              {showEditModal ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative h-[80%] w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <form
                        className=" px-8 pt-6 pb-8 w-full"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                          {/*header*/}
                          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                              Edit Blog Category
                            </h3>
                            <button
                              className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setShowEditModal(false)}
                            >
                              <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>
                          {/*body*/}
                          <div className="relative p-6 flex-auto">
                            <label className="block text-black text-sm font-base mb-1">
                              English Name
                            </label>
                            <input
                              id="englishName"
                              className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                              defaultValue={category?.name?.english}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setEnglishName(e.target.value);
                              }}
                            />
                            {formik.touched.englishName &&
                            formik.errors.englishName ? (
                              <div className="text-[13px] font-medium capitalize text-red-500">
                                {formik.errors.englishName}{" "}
                              </div>
                            ) : null}
                            <label className="block text-black text-sm font-base mb-1">
                              Amharic Name
                            </label>
                            <input
                              id="amharicName"
                              className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                              defaultValue={category?.name?.amharic}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setAmharicName(e.target.value);
                              }}
                            />
                            {formik.touched.amharicName &&
                            formik.errors.amharicName ? (
                              <div className="text-[13px] font-medium capitalize text-red-500">
                                {formik.errors.amharicName}
                              </div>
                            ) : null}
                            <label class="block mb-2 mr-4 text-sm font-medium text-gray-900">
                              <br />
                              Type
                            </label>
                            <div className="flex">
                              <label class="block mb-2 mx-4 text-sm font-bold text-gray-900">
                                {!category?.type ? "null" : category?.type}
                                <br />
                              </label>
                              <select
                                onChange={handleSelect}
                                defaultValue={type}
                              >
                                <option value={null}>Null</option>
                                <option value={1}>Forum</option>
                                {/*                                 <option value={2}>E-commerce</option>
                                 */}{" "}
                                <option value={3}>Blog</option>
                                <option value={4}>Tip</option>
                                {/*    <option value={5}>Exercise</option> */}
                              </select>
                            </div>
                            <p className="flex  flex-row">
                              <label
                                class="block mb-2 mr-4 text-sm font-medium text-gray-900"
                                for="file_input"
                              >
                                <br />
                                Color
                              </label>
                              <input
                                id="color"
                                className="mt-4"
                                defaultValue={`#${category.color.substring(4)}`}
                                type="color"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setColor(e.target.value);
                                }}
                              />
                            </p>
                            {formik.touched.color && formik.errors.color ? (
                              <div className="text-[13px] font-medium capitalize text-red-500">
                                {formik.errors.color}{" "}
                              </div>
                            ) : null}

                            <label className="block text-black text-sm font-base mb-1">
                              Image
                            </label>
                            <ImageList
                              className="ml-4  mr-6  border-2 shadow-lg rounded"
                              sx={{ width: 350, height: 150 }}
                              cols={1}
                              rowHeight={200}
                            >
                              <img
                                src={`${category?.category_image}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${category?.category_image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt="image"
                                loading="lazy"
                              />
                            </ImageList>

                            <input
                              class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                              aria-describedby="file_input_help"
                              onChange={(e) => {
                                formik.handleChange(e);
                                setCategoryImage(e.target.files[0]);
                              }}
                              type="file"
                            />

                            <p
                              class="mt-1 text-sm text-gray-500 "
                              id="file_input_help"
                            >
                              SVG, PNG, JPG or GIF (MAX. 800x400px).
                            </p>
                          </div>
                          {/*footer*/}
                          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                              className="text-gray-900 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowEditModal(false)}
                            >
                              Close
                            </button>
                            <button
                              disabled={editCategoryMutation.isLoading}
                              className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="submit"
                            >
                              {editCategoryMutation.isLoading
                                ? "Editing.."
                                : "Save Changes"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </div>
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditCategoryId(category.id);
                setEnglishName(category?.name?.english);
                setAmharicName(category?.name?.amharic);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteCategoryId(category.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </td>
      </>
    </tr>
  );
};

export default DeleteCategoryModal;
