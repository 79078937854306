import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/auth";
import ReactQuill from "react-quill";
import Editor from "../../../components/Editor";

const WeekEdit = ({
  showEditModal,
  setShowEditModal,
  week,
  id,
  setEditWeeId,

  setEditWeekId,
}) => {
  const [englishName, setEnglishName] = useState(week?.name?.english);
  const [amharicName, setAmharicName] = useState(week?.name?.amharic);
  const [weekNumber, setWeekNumber] = useState(week?.week_number);
  const [weekImage, setWeekImage] = useState(week?.image);

  const [weekTrisemesterId, setWeekTrisemesterId] = useState(
    week?.trimester_id
  );

  const [englishDescription, setEnglishDescription] = useState(
    week?.description?.english
  );
  const [amharicDescription, setAmharicDescription] = useState(
    week?.description?.amharic
  );
  const [order, setOrder] = useState();
  const [editWeek, setEditWeek] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editWeekMutation = useMutation(
    async (editWeek) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `admin/weeks/${editWeek?.id}`,
        formData,
        config
      )
  );

  const editWeekHandler = async (newData) => {
    console.log({ englishName });

    try {
      formData.append("nameEnglish", englishName);
      formData.append("nameAmharic", amharicName);
      formData.append("descriptionEnglish", englishDescription);
      formData.append("descriptionAmharic", amharicDescription);
      formData.append("week_number", weekNumber);
      formData.append("trimester_id", weekTrisemesterId);
      formData.append("week_photo", weekImage);
      formData.append("_method", "patch");
      editWeekMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditWeeId(null);
            toast.success("success");
            setShowEditModal(false);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const TrisemesterData = useQuery(
    ["TrisemesterDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/trimesters`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const trimesterSelectHandler = (e) => {
    const id = e.target.value;
    setWeekTrisemesterId(id);
    console.log(id);
  };

  const formik = useFormik({
    initialValues: {
      englishName: week?.name?.english,
      trimesterEnglishName: week?.trimester_id,
      amharicName: week?.name?.amharic,

      number: week?.week_number,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      trimesterEnglishName: Yup.string().required(
        "Trimester englishName is required"
      ),
      amharicName: Yup.string().required("Amharic Name is required"),

      number: Yup.string().required("number is required"),
    }),
    onSubmit: (values) => {
      editWeekHandler(week?.id);
      setEditWeek(week?.id);
      setEditWeeId(1);
      console.log(englishDescription);
    },
  });
  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative h-[80%] w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit week</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <label className="block text-black text-sm font-base mb-1">
                    English Name
                  </label>
                  <input
                    id="englishName"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={week?.name?.english}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setEnglishName(e.target.value);
                    }}
                  />
                  {formik.touched.englishName && formik.errors.englishName ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.englishName}{" "}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    Amharic Name
                  </label>
                  <input
                    id="amharicName"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={week?.name?.amharic}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setAmharicName(e.target.value);
                    }}
                  />
                  {formik.touched.amharicName && formik.errors.amharicName ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.amharicName}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    English description
                  </label>
                  <Editor
                    value={englishDescription}
                    setValue={(val) => {
                      setEnglishDescription(val);
                      console.log(englishDescription);
                    }}
                  />

                  <label className="block text-black text-sm font-base mb-1">
                    Amharic description
                  </label>
                  <Editor
                    value={amharicDescription}
                    setValue={(val) => {
                      setAmharicDescription(val);
                    }}
                  />

                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Week Number
                    </label>
                    <input
                      id="number"
                      defaultValue={week?.week_number}
                      type="number"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setWeekNumber(e.target.value);
                      }}
                    />
                    {formik.touched.number && formik.errors.number ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.number}{" "}
                      </div>
                    ) : null}
                  </div>
                  <label
                    class="block mb-2 text-sm font-medium "
                    for="file_input"
                  >
                    Week Image file
                  </label>

                  <img className="w-96 p-6" src={week?.week_image} />

                  <input
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    onChange={(e) => {
                      setWeekImage(e.target.files[0]);
                    }}
                    type="file"
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editWeekMutation.isLoading}
                    className="bg-[#636ab1]  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editWeekMutation.isLoading ? "Editing.." : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    </div>
  );
};

export default WeekEdit;
