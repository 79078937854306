import { ImageList, ImageListItem } from "@mui/material";
import React from "react";
import BlogWeekDelete from "./BlogWeekDelete";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useState } from "react";

const BlogWeekTable = ({ item, id, setDeleteBlogWeekId }) => {
  const [showDeleteWeekModal, setShowDeleteWeekModal] = useState(false);

  return (
    <div className="pb-4 bg-gray-100 shadow-lg border-t-4 mt-6 p-1 border-black">
      <p>
        <b>English Name:</b>
        {"  "} {item?.name?.english}
      </p>
      <p>
        <b>Amharic Name:</b>
        {"  "} {item?.name?.amharic}
      </p>{" "}
      <p>
        <b>English Description:</b>
        {"  "} {item?.description?.english}
      </p>{" "}
      <p>
        <b>Amharic Description:</b>
        {"  "} {item?.description?.amharic}
      </p>
      <p>
        <b>week number:</b>
        {"  "} {item?.week_number}
      </p>
      <p>
        <b>trimester id:</b>
        {"  "} {item?.trimester_id}
      </p>
      <p>
        <b>length:</b>
        {"  "} {item?.length}
      </p>
      <p>
        <b>weight:</b>
        {"  "} {item?.weight}
      </p>
      <p>
        <b>size:</b>
        {"  "} {item?.size}
      </p>
      <p>
        <b>created at:</b>
        {"  "} {item?.created_at}
      </p>
      <p>
        <b>updated at:</b>
        {"  "} {item?.updated_at}
      </p>
      <p>
        <b>week image:</b>
        {"  "}
      </p>
      <ImageList
        className="ml-4  mr-6  border-2 shadow-lg rounded"
        sx={{ width: 450, height: 250 }}
        cols={3}
        rowHeight={200}
      >
        {item?.week_image.map((item) => {
          return (
            <>
              <ImageListItem className="p-2" key={item}>
                <img
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            </>
          );
        })}
      </ImageList>
      <div className="w-[10%]  flex justify-end pr-4">
        {showDeleteWeekModal ? (
          <>
            <BlogWeekDelete
              blogWeek={item}
              setShowDeleteWeekModal={setShowDeleteWeekModal}
              setDeleteBlogWeekId={setDeleteBlogWeekId}
            />
          </>
        ) : null}

        <button
          className=""
          onClick={() => {
            setShowDeleteWeekModal(true);
          }}
        >
          <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default BlogWeekTable;
