import {
  CssBaseline,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineArrowBack } from "react-icons/md";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import { shadows } from "@mui/system";
import ReactQuill from "react-quill";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BlogTagDelete from "./subBlogs/BlogTagDelete";
import BlogTagTable from "./subBlogs/BlogTagTable";
import BlogWeekTable from "./subBlogs/BlogWeekTable";
import BlogTrimesterTable from "./subBlogs/BlogTrimesterTable";
import BlogCategoryTable from "./subBlogs/BlogCategoryTable";
import { toast } from "react-toastify";

const BlogView = () => {
  const [deleteBlogTagId, setDeleteBlogTagId] = useState(null);
  const [deleteBlogWeekId, setDeleteBlogWeekId] = useState(null);
  const [deleteBlogTrimesterId, setDeleteBlogTrimesterId] = useState(null);
  const [deleteBlogCategoryId, setDeleteBlogCategoryId] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const BlogData = useQuery(
    [
      "BlogDataApi",
      deleteBlogTagId,
      deleteBlogWeekId,
      deleteBlogTrimesterId,
      deleteBlogCategoryId,
    ],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/blogs/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
        if (
          deleteBlogTagId == 1 ||
          deleteBlogWeekId == 1 ||
          deleteBlogTrimesterId == 1 ||
          deleteBlogCategoryId == 1
        ) {
          toast.success("Delete Success");
        }

        setDeleteBlogTagId(null);
        setDeleteBlogWeekId(null);
        setDeleteBlogTrimesterId(null);
        setDeleteBlogCategoryId(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [BlogData]);

  console.log(BlogData?.data?.data?.data?.blog_categories[0]);
  console.log(BlogData?.data?.data?.data?.trimesters[0]);

  return (
    <>
      {BlogData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {BlogData.isLoading || BlogData.isFetching ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <React.Fragment>
          <CssBaseline />
          <Container sx={{}} maxWidth="100vh">
            <Box
              sx={{
                bgcolor: "#f1f1f1",

                height: "fill",
                margin: "0px",
                padding: "0px",
              }}
            >
              <button>
                <MdOutlineArrowBack
                  onClick={() => navigate(`/blog`)}
                  className="text-[#636ab1] text-4xl"
                />
              </button>
              <div className="flex flex-col p-6">
                <div className="flex flex-row ">
                  <div className="flex flex-col ">
                    <Typography
                      variant="h8"
                      className="text-b"
                      gutterBottom
                      sx={{ marginTop: "10px", marginLeft: "10px" }}
                    >
                      <b>Blog English Title:</b>
                      <br />
                      {BlogData?.data?.data?.data?.title.english}
                      <br />
                      <div className="border-2 mt-4 ">
                        <b>Blog English Description:</b>
                        <br />
                        <ReactQuill
                          value={
                            BlogData?.data?.data?.data?.description?.english
                          }
                          readOnly={true}
                          theme={"bubble"}
                        />
                      </div>
                      <br />
                      <br />
                    </Typography>
                    <Typography
                      variant="h8"
                      className="text-b"
                      sx={{ marginTop: "10px", marginLeft: "10px" }}
                    >
                      <b>Blog Amharic Title :</b>

                      {BlogData?.data?.data?.data?.title.amharic}
                      <br />
                      <div className="border-2 mt-4 ">
                        <b>Blog Amharic Description :</b>
                        <br />
                        <ReactQuill
                          value={
                            BlogData?.data?.data?.data?.description?.amharic
                          }
                          readOnly={true}
                          theme="bubble"
                        />
                      </div>
                      <br />
                      <br />
                    </Typography>
                    <div className="text-xs flex w-[100%] justify-end flex-col">
                      <b>author_id:</b> {BlogData?.data?.data?.data?.author_id}
                      <b>blog epic:</b> {BlogData?.data?.data?.data?.blog_epic}
                      <b>has liked? :</b>{" "}
                      {BlogData?.data?.data?.data?.has_liked === "true"
                        ? "true"
                        : "false"}
                      <b>likes:</b> {BlogData?.data?.data?.data?.likes_count}
                      <b>created at:</b>{" "}
                      {BlogData?.data?.data?.data?.created_at}
                      <b>updated at:</b>{" "}
                      {BlogData?.data?.data?.data?.updated_at}
                    </div>
                  </div>
                  <div className="pl-6 pt-6">
                    <ImageList
                      sx={{ width: 150, height: 250 }}
                      cols={1}
                      rowHeight={164}
                    >
                      {BlogData?.data?.data?.data?.blog_image?.map((item) => {
                        return (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}?w=164&h=164&fit=crop&auto=format`}
                              srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              alt={item.title}
                              loading="lazy"
                            />
                          </ImageListItem>
                        );
                      })}
                    </ImageList>
                  </div>
                </div>
                <div className="w-fill flex flex-col bg-white shadow-lg p-4 rounded m-6">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    <b>Categories</b>{" "}
                  </Typography>

                  {BlogData?.data?.data?.data?.blog_categories?.map((item) => {
                    return (
                      <div key={item}>
                        <BlogCategoryTable
                          item={item}
                          id={id}
                          setDeleteBlogCategoryId={setDeleteBlogCategoryId}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="w-fill flex flex-col bg-white shadow-lg p-4 rounded m-6">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    <b>Trimesters</b>{" "}
                  </Typography>
                  <Typography
                    className=""
                    variant="body"
                    fontSize={"12px"}
                    gutterBottom
                    sx={{ marginTop: "4px", marginLeft: "10px" }}
                  >
                    {BlogData?.data?.data?.data?.trimesters?.map((item) => {
                      return (
                        <div key={item}>
                          <BlogTrimesterTable
                            item={item}
                            id={id}
                            setDeleteBlogTrimesterkId={setDeleteBlogTrimesterId}
                          />
                        </div>
                      );
                    })}
                  </Typography>
                </div>
                <div className="w-fill flex flex-col bg-white shadow-lg p-4 rounded m-6">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    <b>Weeks</b>{" "}
                  </Typography>
                  <Typography
                    className=""
                    variant="body"
                    fontSize={"12px"}
                    gutterBottom
                    sx={{ marginTop: "4px", marginLeft: "10px" }}
                  >
                    {BlogData?.data?.data?.data?.weeks?.map((item) => {
                      return (
                        <div key={item}>
                          <BlogWeekTable
                            item={item}
                            id={id}
                            setDeleteBlogWeekId={setDeleteBlogWeekId}
                          />
                        </div>
                      );
                    })}
                  </Typography>
                </div>
                <div className="w-fill flex flex-col bg-white shadow-lg p-4 rounded m-6">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    <b>Tags</b>{" "}
                  </Typography>
                  <Typography
                    className=""
                    variant="body"
                    fontSize={"12px"}
                    gutterBottom
                    sx={{ marginTop: "4px", marginLeft: "10px" }}
                  >
                    {BlogData?.data?.data?.data?.tags?.map((item, id) => {
                      return (
                        <div key={id}>
                          <BlogTagTable
                            blogId={BlogData?.data?.data?.data?.id}
                            item={item}
                            id={id}
                            setDeleteBlogTagId={setDeleteBlogTagId}
                          />
                        </div>
                      );
                    })}
                  </Typography>
                </div>
              </div>
            </Box>
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default BlogView;
