import React, { useState } from "react";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import DeleteTip from "./DeleteTip";
import EditTip from "./EditTip";

const TipTable = ({ tip, id, setDeleteTipId, setEditTipId, setEditTiId }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <tr key={id} class="bg-white border-b ">
      <td class="px-6 py-4">
        <p>{tip?.title?.english}</p>
      </td>
      <td class="px-6 py-4">
        <p>{tip?.title?.amharic}</p>
      </td>

      <td class="px-6 py-4">
        <div className="flex justify-end">
          <div>
            {showDeleteModal ? (
              <>
                <DeleteTip
                  tip={tip}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeleteTipId={setDeleteTipId}
                />
              </>
            ) : null}
          </div>
          <div>
            {showEditModal ? (
              <>
                <EditTip
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  tip={tip}
                  id={id}
                  setEditTipId={setEditTipId}
                  setEditTiId={setEditTiId}
                />
              </>
            ) : null}
          </div>
          <div className="w-32">
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditTipId(tip?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteTipId(tip?.id);
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TipTable;
