import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../context/auth";
import { Card, ImageList, ImageListItem } from "@mui/material";
import ReactPlayer from "react-player";

const ExerciseMediaEdit = ({
  showEditModal,
  setShowEditModal,
  exercise,
  id,
  setEditMedId,

  setEditExerciseMediaId,
}) => {
  const [caption, setCaption] = useState(exercise?.caption);
  const [order, setOrder] = useState(exercise?.order);
  const [image, setImage] = useState( exercise?.exercise_image?.url);
  const [video, setVideo] = useState(exercise?.exercise_video?.url);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editExerciseMediaMutation = useMutation(
    async (editExercise) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/exercise-media/${exercise?.id}`,
        formData,
        config
      )
  );

  const editExerciseMediaHandler = async (newData) => {
    try {
      formData.append("exerciseId", id);
      formData.append("caption", caption);
      formData.append("order", order);
      formData.append("exercise_photo", image);
      formData.append("exercise_video", video);
      formData.append("_method", "patch");

      editExerciseMediaMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            toast.success("Edit success");
            setEditMedId(true)
            setCaption(null);
            setOrder(null);
            setImage(null);
            setVideo(null);
          },
          onError: (err) => {
            toast.error(err?.response?.data?.message ?? "Edit failed");
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      englishName: exercise?.caption,
      order: exercise?.order,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("Caption is required"),
      order: Yup.string().required("order is required"),
    }),
    onSubmit: () => {
      editExerciseMediaHandler();
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto max-h-[80%] my-6 mx-auto max-w-8xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add ExerciseMedia</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 ">
                  <div className="flex flex-col w-full">
                    <div className="mx-6 w-[50%]">
                      <label className="block text-black text-sm font-base mb-1">
                        Caption
                      </label>
                      <input
                        id="englishName"
                        value={formik.values.englishName}
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        onChange={(e) => {
                          formik.handleChange(e);
                          setCaption(e.target.value);
                        }}
                      />
                      {formik.touched.englishName &&
                      formik.errors.englishName ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishName}{" "}
                        </div>
                      ) : null}
                      <label className="block text-black text-sm font-base mb-1">
                        Order
                      </label>
                      <input
                        id="order"
                        value={formik.values.order}
                        type="number"
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        onChange={(e) => {
                          formik.handleChange(e);
                          setOrder(e.target.value);
                        }}
                      />
                      {formik.touched.order && formik.errors.order ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.order}{" "}
                        </div>
                      ) : null}
                    </div>
                    <div className="mx-6 w-[50%] grid grid-cols-1">
                      <label className="block text-black text-sm font-base mb-1">
                        Image
                      </label>
                      <div>
                        <ImageList cols={1} rowHeight={164}>
                          {exercise?.exercise_image?.map((item) => (
                            <ImageListItem key={item.uuid}>
                              <img
                                src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.uuid}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </div>
                      <input
                        class="block w-full text-sm text-gray-900 border mt-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="file_input_help"
                        defaultValue={exercise?.exercise_image?.url}
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        type="file"
                      />

                      <p
                        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_input_help"
                      >
                        SVG, PNG, JPG or GIF...
                      </p>
                    </div>
                    <div className="mx-6 w-[50%] grid grid-cols-1">
                      <label className="block text-black text-sm font-base mb-1">
                        Video
                      </label>
                      {!exercise.exercise_video ? (
                        <>No</>
                      ) : (
                        <>
                          {exercise?.exercise_video?.map((item) => (
                            <div className="">
                              <ReactPlayer url={item.url} controls />
                            </div>

                            /*           <VideoCard src={"https://www.youtube.com/watch?v=sY8lYexr72U"} />
                             */
                          ))}
                        </>
                      )}
                      <input
                        class="flex  w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="file_input_help"
                        defaultValue={exercise?.exercise_video?.url}
                        onChange={(e) => {
                          setVideo(e.target.files[0]);
                        }}
                        type="file"
                      />

                      <p
                        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_input_help"
                      >
                        Mp4, WMV, FLV, MKV...
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editExerciseMediaMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editExerciseMediaMutation.isLoading
                      ? "saving.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      <ToastContainer />
    </div>
  );
};

export default ExerciseMediaEdit;
