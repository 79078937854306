import axios from "axios";
import React, { useState } from "react";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/auth";
import ExpertReviewTable from "./ExpertReviewTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExpertReview = () => {
  const [showModal, setShowModal] = useState(false);
  const [editExpertStatusId, setEditExpertStatusId] = useState();
  

  const [editStatId, setEditStatId] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ExpertReviewData = useQuery(
    ["ExpertReviewDataApi", showModal , editExpertStatusId, editStatId],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/expert-reviews`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
      
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  return (
    <div>
      <h2 class="mb-4 ml-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl">
        ExpertReview
      </h2>
     
    
      {ExpertReviewData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div class="relative shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                 
                </tr>
              </thead>
              <tbody>
                {ExpertReviewData?.data?.data?.data.map((expertReview, id) => (
                  <ExpertReviewTable
                    expertReview={expertReview}
                    id={id}
                    setEditExpertStatusId={setEditExpertStatusId}
                    setEditStatId={setEditStatId}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ExpertReview;
