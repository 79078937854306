import React, { useState } from "react";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/auth";

import ForumTable from "./ForumTable";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../../components/Pagination";
const Forum = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteForumId, setDeleteForumId] = useState(false);
  const [editForumId, setEditForumId] = useState(false);

  const [viewForumId, setViewForumId] = useState(false);
  const [editOrdId, setEditOrdId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ForumData = useQuery(
    ["ForumDataApi", currentPage],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}forums?page=${currentPage}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
        setDeleteForumId(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  console.log(ForumData.data);
  return (
    <div>
      <h2 class="mb-4 ml-4  text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl">
        Forums
      </h2>

      {ForumData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {ForumData.isFetching ? (
        <div className=" h-[44vh]  flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div class="relative shadow-md sm:rounded-lg">
            <TableContainer class="w-full text-sm text-left   text-gray-500">
              <Table>
                <TableHead class="text-xs text-gray-700 uppercase ">
                  <TableRow>
                    <TableCell />

                    <TableCell align="left">Questions</TableCell>

                    <TableCell align="center">Answers</TableCell>
                    <TableCell align="right">Category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ForumData?.data?.data?.data?.data?.map((forum, id) => (
                    <ForumTable key={id} forum={forum} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div></div>
          </div>
          <div className="flex justify-center py-6 pr-10 ">
            {ForumData?.data?.data?.data?.links?.map((item, id) => {
              return (
                <div key={id}>
                  <Pagination  item = {item} currentPage={currentPage} setCurrentPage={setCurrentPage} MainData={ForumData} />
                </div>
              );
            })}
          </div>
      
        </div>
      )}
    </div>
  );
};

export default Forum;
