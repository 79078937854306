import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Editor from "../../../components/Editor";

const EditTip = ({
  showEditModal,
  setShowEditModal,
  tip,
  id,
  setEditTipId,
  setEditTiId,
}) => {
  const [englishTipName, setEnglishTipName] = useState(tip?.title?.english);
  const [amharicTipName, setAmharicTipName] = useState(tip?.title?.amharic);
  const [tipEnglishDescription, setTipEnglishDescription] = useState(
    tip?.description?.english
  );
  const [tipAmharicDescription, setTipAmharicDescription] = useState(
    tip?.tip_image
  );
  const [editTip, setEditTip] = useState();
  const [tipImage, setTipImage] = useState(tip?.title?.amharic);
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editTipMutation = useMutation(
    async (editTip) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `admin/tips/${editTip?.id}`,
        formData,
        config
      )
  );
  const editTipHandler = async (newData) => {
    console.log({ englishTipName });

    try {
      formData.append("titleEnglish", englishTipName);
      formData.append("titleAmharic", amharicTipName);
      formData.append("descriptionEnglish", tipEnglishDescription);
      formData.append("descriptionAmharic", tipAmharicDescription);
      formData.append("tip_photo", tipImage);
      formData.append("_method", "patch");
      editTipMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("Edit success!");
            setShowEditModal(false);
            setEditTiId(null);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      englishName: tip?.title?.english,
      amharicName: tip?.title?.amharic,
      englishDescription: tip?.description?.english,
      amharicDescription: tip?.description?.amharic,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDescription: Yup.string().required(
        "English Description is required"
      ),
      amharicDescription: Yup.string().required(
        "Amharic Description is required"
      ),
    }),
    onSubmit: (values) => {
      editTipHandler(tip?.id);
      setEditTip(tip?.id);
      setEditTipId(1);
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative h-[80%] w-auto my-6 mx-auto max-w-[80%] ">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit Tip</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flex flex-row">
                  <div className="relative p-6 flex-auto m-6 w-[50%] ">
                    <div className="h-[50%]">
                      <label className="block text-black text-sm font-base mb-1">
                        English Tip Name
                      </label>
                      <input
                        name="englishName"
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        defaultValue={tip?.title?.english}
                        onChange={(e) => {
                          formik.handleChange(e);

                          setEnglishTipName(e.target.value);
                        }}
                      />
                      {formik.touched.englishName &&
                      formik.errors.englishName ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishName}
                        </div>
                      ) : null}
                    </div>
                    <label className="block text-black text-sm font-base mb-1">
                      Amharic Tip Name
                    </label>
                    <input
                      name="amharicName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      defaultValue={tip?.title?.amharic}
                      onChange={(e) => {
                        formik.handleChange(e);

                        setAmharicTipName(e.target.value);
                      }}
                    />
                    {formik.touched.amharicName && formik.errors.amharicName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicName}
                      </div>
                    ) : null}
                  </div>
                  <div className="m-6">
                    <div>
                      <label className="block text-black text-sm font-base mb-1">
                        English Tip Description
                      </label>
                      <Editor
                        id="englishDescription"
                        value={formik.values.englishDescription}
                        setValue={(val) => {
                          setTipEnglishDescription(val);
                          formik.setFieldValue("englishDescription", val);
                        }}
                      />{" "}
                      {formik.touched.englishDescription &&
                      formik.errors.englishDescription ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishDescription}
                        </div>
                      ) : null}
                    </div>{" "}
                    <div>
                      <label className="block text-black text-sm font-base mb-1 mt-6">
                        <br/>
                        Amharic Tip Description
                      </label>
                      <Editor
                        id="amharicDescription"
                        value={formik.values.amharicDescription}
                        setValue={(val) => {
                          setTipAmharicDescription(val);
                          formik.setFieldValue("amharicDescription", val);
                        }}
                      />{" "}
                      {formik.touched.amharicDescription &&
                      formik.errors.amharicDescription ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.amharicDescription}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="w-[50%] m-6"
                  >
                    <label
                      class="block mb-2 text-sm font-medium "
                      for="file_input"
                    >
                      Tip Image file
                    </label>
                    <img className="w-96 p-6" src={tip?.tip_image} />
                    <input
                      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="file_input_help"
                      onChange={(e) => {
                        setTipImage(e.target.files[0]);
                      }}
                      type="file"
                    />
                    <p
                      class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      SVG, PNG, JPG or GIF (MAX. 800x400px).
                    </p>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editTipMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editTipMutation.isLoading ? "Editing.." : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      <ToastContainer />
    </div>
  );
};

export default EditTip;
