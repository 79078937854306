import {
  CssBaseline,
  ImageList,
  ImageListItem,
  MenuItem,
  Typography,
} from "@mui/material";

import { FiCheck } from "react-icons/fi";
import { RiDeleteBin2Fill } from "react-icons/ri";
import DeleteWorkingDays from "./DeleteWorkingDays";
import Select from "react-select";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect } from "react";
import { AiFillCheckSquare, AiFillEye } from "react-icons/ai";

import { MdOutlineArrowBack } from "react-icons/md";
import { Bars } from "react-loader-spinner";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import AddWorkingHours from "./working hour/AddWorkingHours";
import TimeConverter from "../../../components/TimeConverter";
import DeleteWorkingHours from "./working hour/DeleteWorkingHours";

const ManageExpertView = () => {
  const [expertWorkingDays, setExpertWorkingDays] = useState(null);
  const [showAddWorkingHourModal, setShowAddWorkingHourModal] = useState(false);
  const [showDeleteWorkingDays, setShowDeleteWorkingDays] = useState(false);
  const [showDeleteWorkingHours, setShowDeleteWorkingHours] = useState(false);
  const [deleteWorkingDaysId, setDeleteWorkingDaysId] = useState(null);
  const [deleteWorkingHoursId, setDeleteWorkingHoursId] = useState(null);
  const [workingDayId, setWorkingDayId] = useState(null);
  const [workingDays, setWorkingDays] = useState([]);

  const [days, setDays] = useState(0);
  console.log(days);

  const { id } = useParams();
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ManageExpertData = useQuery(
    ["ManageExpertDataApi", days],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}admin/experts/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(specialityData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  const GetWorkingDaysData = useQuery(
    ["GetWorkingDaysDataApi", days],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}admin/working-days?expertId=${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(specialityData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  const tempObj = [];

  tempObj.push({ id: "1", name: "Monday" });
  tempObj.push({ id: "2", name: "Tuesday" });
  tempObj.push({ id: "3", name: "Wednesday" });
  tempObj.push({ id: "4", name: "Thursday" });
  tempObj.push({ id: "5", name: "Friday" });
  tempObj.push({ id: "6", name: "Saturday" });
  tempObj.push({ id: "7", name: "Sunday" });

  const myDefault = [];
  const workingDaysOptions = [...tempObj];

  const addWorkingDaysHandler = () => {
    addWorkingDaysMutationSubmitHandler();
    setDays(1);
    console.log(days);
  };
  const addWorkingDaysMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/working-days`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addWorkingDaysMutationSubmitHandler = async (values) => {
    let formData = new FormData();

    formData.append("expertId", id);
    var workingDaysData = [];
    if (expertWorkingDays) {
      for (var i = 0; i < expertWorkingDays.length; i++) {
        workingDaysData = [expertWorkingDays[i].name];
        console.log(expertWorkingDays[i].name);
        formData.append("days[]", expertWorkingDays[i].name);
      }
    }

    try {
      addWorkingDaysMutation.mutate(formData, {
        onSuccess: () => {
          window.location.reload();
        },
        onError: (err) => {
          console.log({ err });
          toast.error(
            err?.response?.data?.message ?? err.response.data.message
          );
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const WorkingDaysData = useQuery(
    ["WorkingDaysDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}admin/working-days?expertId=${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(specialityData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ManageExpertData]);
  return (
    <>
      {ManageExpertData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {ManageExpertData.isFetching || GetWorkingDaysData.isRefetching ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <React.Fragment>
          <CssBaseline />
          <Container sx={{ bgcolor: "#f1f1f1" }} maxWidth="100vh">
            <button>
              <MdOutlineArrowBack
                onClick={() => navigate(`/manage-expert`)}
                className="text-[#636ab1] text-4xl"
              />
            </button>
            <div className="flex flex-row">
              <div className="w-[50%] flex flex-col">
                {" "}
                <div className="text-3xl">
                  <b>Expert Name: </b>
                  {ManageExpertData?.data?.data?.data?.user?.name}
                  <br />
                </div>
                <br />
                <div className="text-3xl">
                  <b>Expert phone: </b>
                  {ManageExpertData?.data?.data?.data?.user.phone}
                  <br />
                  <br />
                </div>
                <div>
                  <b>Biography: </b>
                  {ManageExpertData?.data?.data?.data?.biography}
                  <br />
                </div>
                <div>
                  <b>Statement: </b>
                  {ManageExpertData?.data?.data?.data?.profStatement}
                  <br />
                </div>
                <br />
                <div className=" flex flex-row border-t border-black">
                  <div className=" w-1/2 flex flex-col ">
                    <div>
                      <b>Date of Birth: </b>
                      {ManageExpertData?.data?.data?.data?.dob}
                      <br />
                    </div>
                    <div>
                      <b>Patients: </b>
                      {ManageExpertData?.data?.data?.data?.patient_count}
                      <br />
                      <b>Babies: </b>
                      {ManageExpertData?.data?.data?.data?.babies_count}
                      <br />
                    </div>
                  </div>
                  <div className=" w-1/2 flex justify-end">
                    <div className=" flex  flex-col  "></div>
                  </div>
                </div>
                <div className=" flex flex-row mt-4 border-t border-black">
                  <div className=" w-1/2 flex flex-col ">
                    <h1 className="flex text-xl justify-start p-4">
                      <b>User</b>
                    </h1>
                    <div>
                      <b>ID: </b>
                      {ManageExpertData?.data?.data?.data?.user?.id}
                      <br />
                    </div>
                    <div>
                      <b>Name: </b>
                      {ManageExpertData?.data?.data?.data?.user?.name}
                      <br />
                      <b>email: </b>
                      {ManageExpertData?.data?.data?.data?.user?.email}
                      <br />
                    </div>
                    <div>
                      <b>phone: </b>
                      {ManageExpertData?.data?.data?.data?.user?.phone}
                      <br />
                      <b>otp sent at: </b>
                      {ManageExpertData?.data?.data?.data?.user?.otp_sent_at}
                      <br />
                    </div>{" "}
                    <div>
                      <b>status: </b>
                      {ManageExpertData?.data?.data?.data?.user?.status}
                      <br />
                    </div>
                    <div>
                      <b>created at: </b>
                      {ManageExpertData?.data?.data?.data?.user?.created_at}
                      <br />
                      <b>updated at: </b>
                      {ManageExpertData?.data?.data?.data?.user?.updated_at}
                      <br />
                    </div>
                    <div>
                      <b>role id: </b>
                      {ManageExpertData?.data?.data?.data?.user?.role_id}
                      <br />
                      <b>role name: </b>
                      {ManageExpertData?.data?.data?.data?.user?.role?.name}
                      <br />
                    </div>{" "}
                    <div className="pl-6 pt-6">
                      <b>User Images: </b>
                      <br />
                      <br />
                      <ImageList
                        sx={{ width: 150, height: 250 }}
                        cols={1}
                        rowHeight={164}
                      >
                        {ManageExpertData?.data?.data?.data?.user?.user_image?.map(
                          (item) => {
                            return (
                              <ImageListItem key={item}>
                                <img
                                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt={item.title}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            );
                          }
                        )}
                      </ImageList>
                    </div>
                  </div>
                  <div className=" w-1/2 flex justify-end">
                    <div className=" flex  flex-col  ">
                      <h1 className="flex text-xl justify-start p-4">
                        <b>Speciality</b>
                      </h1>
                      <div>
                        <b>Id: </b>
                        {ManageExpertData?.data?.data?.data?.speciality?.id}
                        <br />
                      </div>{" "}
                      <div>
                        <b>Speciality: </b>
                        {
                          ManageExpertData?.data?.data?.data?.speciality
                            ?.speciality
                        }
                        <br />
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end   ">
                <div className="flex flex-row  ">
                  <div className=" flex flex-row mt-2 border-t border-black">
                    <div className="  flex justify-end">
                      <div className=" flex  flex-col  ">
                        <h1 className="flex text-xl justify-start py-4">
                          <b>Working Days</b> <br />
                        </h1>
                        <div>
                          <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900 "
                          >
                            <br />
                            Add Working Days
                          </label>
                          <Select
                            isMulti={true}
                            styles={{
                              menuPortal: (base) => ({
                                zIndex: 999999,
                              }),
                            }}
                            options={workingDaysOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={myDefault}
                            onChange={(newSelection) => {
                              console.log({ newSelection });
                              setExpertWorkingDays(newSelection);
                            }}
                          />
                          <button
                            className="py-2 rounded-lg px-4 border bg-[#636ab1] text-white"
                            onClick={() => {
                              addWorkingDaysHandler();
                            }}
                          >
                            Set Working Days
                          </button>
                        </div>

                        <h1 className="flex text-x justify-start py-4">
                          <b>
                            {GetWorkingDaysData.data?.data?.data.map(
                              (item, id) => {
                                return (
                                  <>
                                    <div key={id}>
                                      {" "}
                                      <div className=" pt-20 grid grid-cols-3 border-b border-black border-t">
                                        <div></div>
                                        <div className="grid grid-cols-3">
                                          <p className="pt-1 px-1 flex justify-end">
                                            <FiCheck />
                                          </p>{" "}
                                          {item.day}{" "}
                                          <button
                                            className="flex justify-center ml-4"
                                            onClick={() => {
                                              setDeleteWorkingDaysId(item);
                                              setShowDeleteWorkingDays(true);
                                            }}
                                          >
                                            <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
                                          </button>
                                        </div>{" "}
                                        <div className="flex justify-center">
                                          <button
                                            className="py-2 item-right rounded-lg px-2 text-xs mx-2 border bg-[#636ab1] text-white"
                                            onClick={() => {
                                              setShowAddWorkingHourModal(true);
                                              setWorkingDayId(item.id);
                                            }}
                                          >
                                            + Working Hours
                                          </button>
                                        </div>
                                      </div>
                                      <p className="text-xs flex-col grid grid-cols-3 ">
                                        <div>Working Hours</div>

                                        <div className="flex flex-col">
                                          {item?.working_hours?.map((item) => {
                                            return (
                                              <div key={item}>
                                                <div className="flex">
                                                  <p className="px-1">
                                                    <TimeConverter
                                                      time={item.start_time}
                                                    />
                                                  </p>
                                                  -
                                                  <p className="px-1">
                                                    <TimeConverter
                                                      time={item.end_time}
                                                    />
                                                  </p>
                                                  <button
                                                    className="flex justify-center ml-4"
                                                    onClick={() => {
                                                      setDeleteWorkingHoursId(
                                                        item
                                                      );
                                                      setShowDeleteWorkingHours(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <RiDeleteBin2Fill className="text-gray-500 text-sm " />
                                                  </button>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </b>{" "}
                          <br />
                        </h1>
                        {showDeleteWorkingDays ? (
                          <>
                            <DeleteWorkingDays
                              item={deleteWorkingDaysId}
                              setShowDeleteWorkingDays={
                                setShowDeleteWorkingDays
                              }
                              setDays={setDays}
                            />
                          </>
                        ) : null}
                        {showDeleteWorkingHours ? (
                          <>
                            <DeleteWorkingHours
                              item={deleteWorkingHoursId}
                              setShowDeleteWorkingHours={
                                setShowDeleteWorkingHours
                              }
                              setDays={setDays}
                            />
                          </>
                        ) : null}

                        {showAddWorkingHourModal ? (
                          <>
                            <AddWorkingHours
                              WorkingDayId={workingDayId}
                              setShowAddWorkingHourModal={
                                setShowAddWorkingHourModal
                              }
                              setDays={setDays}
                            />
                          </>
                        ) : null}
                        <h1 className="flex text-xl justify-start py-4">
                          <b>Qualifications</b> <br />
                        </h1>

                        <h1 className="flex text-xl justify-start py-4">
                          <b>languages</b> <br />
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default ManageExpertView;
