import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/auth";
import ReactQuill from "react-quill";
import Editor from "../../../components/Editor";
import { Switch } from "@mui/material";
import Select from "react-select";

const BlogEdit = ({
  showEditModal,
  setShowEditModal,
  blog,
  id,
  setEditBloId,
  setEditBlogId,
  setForHomePageid,
}) => {
  const [englishName, setEnglishName] = useState(blog?.title?.english);
  const [amharicName, setAmharicName] = useState(blog?.title?.amharic);
  const [blogCategoryId, setBlogCategoryId] = useState();

  const [blogTrisemester, setBlogTrisemester] = useState();
  const [blogTag, setBlogTag] = useState();
  const [blogWeeks, setBlogWeeks] = useState();
  const [forHomePage, setForHomePage] = useState(blog?.for_homepage);
  const [blogImage, setBlogImage] = useState(blog?.for_image);

  const [englishDescription, setEnglishDescription] = useState(
    blog?.description?.english
  );
  const [amharicDescription, setAmharicDescription] = useState(
    blog?.description?.amharic
  );
  const [order, setOrder] = useState(blog?.order);
  const [editBlog, setEditBlog] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editBlogMutation = useMutation(
    async (editBlog) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `admin/blogs/${editBlog?.id}`,
        formData,
        config
      )
  );

  const editBlogHandler = async (newData) => {
    console.log({ englishName });

    try {
      formData.append("titleEnglish", englishName);
      formData.append("titleAmharic", amharicName);
      formData.append("descriptionEnglish", englishDescription);
      formData.append("descriptionAmharic", amharicDescription);
      formData.append("order", order);
      formData.append("blog_photo", blogImage);

      var weekData = [];
      if (blogWeeks) {
        for (var i = 0; i < blogWeeks.length; i++) {
          weekData = [...weekData, blogWeeks[i].id];
          formData.append("weeks[]", weekData[i]);
        }
      }

      var trimesterData = [];
      if (blogTrisemester) {
        for (var i = 0; i < blogTrisemester.length; i++) {
          trimesterData = [...trimesterData, blogTrisemester[i].id];
          formData.append("trimesters[]", trimesterData[i]);
        }
      }

      var tagData = [];
      if (blogTag) {
        for (var i = 0; i < blogTag.length; i++) {
          tagData = [...tagData, blogTag[i].id];
          formData.append("tags[]", tagData[i]);
        }
      }
      var categoryData = [];
      if (blogCategoryId) {
        for (var i = 0; i < blogCategoryId.length; i++) {
          categoryData = [...categoryData, blogCategoryId[i].id];
          formData.append("categories[]", categoryData[i]);
        }
      }
      formData.append("_method", "patch");
      editBlogMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditBloId(null);
            setBlogWeeks(null);
            setBlogTrisemester(null);
            setBlogTag(null);
            setBlogCategoryId(null);
            toast.success("success");
            setShowEditModal(false);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const params2 = new URLSearchParams();

  const forHomePageMutation = useMutation(
    async (forHomeId) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/blog-for-homepage/${forHomeId?.id}`,
        params2,
        config
      )
  );
  const forHomePageHandler = async (newData) => {
    try {
      params2.append("for_homepage", forHomePage);

      forHomePageMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("success for homepage");
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const myDefault = [];

  const TrisemesterData = useQuery(
    ["TrisemesterDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/trimesters`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const tempObj = [];
  TrisemesterData?.data?.data?.data.map((item) => {
    tempObj.push({ id: item.id, name: item.name.english });
  });
  const trimesterOptions = [...tempObj];

  const TagData = useQuery(
    ["TagDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/tags`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  const tempTagObj = [];
  TagData?.data?.data?.data.map((item) => {
    tempTagObj.push({ id: item.id, name: item.name.english });
  });

  const tagOptions = [...tempTagObj];

  const WeekData = useQuery(
    ["WeekDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/weeks`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  const tempWeekObj = [];
  WeekData?.data?.data?.data.map((item) => {
    tempWeekObj.push({ id: item.id, name: item.name.english });
  });

  const weekOptions = [...tempWeekObj];

  const CategoryData = useQuery(
    ["CategoryDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/categories`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const categorySelectHandler = (e) => {
    const id = e.target.value;
    setBlogCategoryId(id);
    console.log(id);
  };
  const tempCategoryObj = [];
  CategoryData?.data?.data?.data.map((item) => {
    tempCategoryObj.push({ id: item.id, name: item.name.english });
  });

  const categoryOptions = [...tempCategoryObj];
  console.log(categoryOptions);

  const formik = useFormik({
    initialValues: {
      englishName: blog?.title?.english,
      amharicName: blog?.title?.amharic,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
    }),
    onSubmit: (values) => {
      editBlogHandler(blog?.id);
      setEditBlog(blog?.id);
      setEditBlogId(1);
      forHomePageHandler(blog.id);
      setForHomePageid(1);
    },
  });
  const label = { inputProps: { "aria-label": "Switch " } };

  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative h-[80%] w-[60%] my-6 mx-auto">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit blog</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex justify-end">
                    {" "}
                    <label className="block text-black font-bold text-lg font-base mt-1">
                      For Home Page
                    </label>
                    {!blog?.for_homepage ? (
                      <Switch
                        size="medium"
                        onChange={() => {
                          setForHomePage(true);
                        }}
                      />
                    ) : (
                      <Switch
                        size="medium"
                        defaultChecked
                        onChange={() => {
                          setForHomePage(false);
                        }}
                      />
                    )}
                    <br />
                  </div>
                  <label className="block text-black text-sm font-base mb-1">
                    English Name
                  </label>
                  <input
                    id="englishName"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={blog?.title?.english}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setEnglishName(e.target.value);
                    }}
                  />
                  {formik.touched.englishName && formik.errors.englishName ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.englishName}{" "}
                    </div>
                  ) : null}
                  <label className="block mt-4 text-black text-sm font-base mb-1">
                    Amharic Name
                  </label>
                  <input
                    id="amharicName"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={blog?.title?.amharic}
                    onChange={(e) => {
                      formik.handleChange(e);

                      setAmharicName(e.target.value);
                    }}
                  />
                  {formik.touched.amharicName && formik.errors.amharicName ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.amharicName}{" "}
                    </div>
                  ) : null}
                  <label className="block mt-4 text-black text-sm font-base mb-1">
                    English description
                  </label>
                  <Editor
                    value={englishDescription}
                    setValue={setEnglishDescription}
                  />
                  <label className="block mt-6 text-black text-sm font-base mb-1">
                    Amharic description
                  </label>
                  <Editor
                    value={amharicDescription}
                    setValue={setAmharicDescription}
                  />

                  <label
                    class="block mb-2 mt-6 text-sm font-medium "
                    for="file_input"
                  >
                    Blog Image file
                  </label>

                  <img className="w-96 p-6" src={blog?.blog_image} />

                  <input
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    onChange={(e) => {
                      setBlogImage(e.target.files[0]);
                    }}
                    type="file"
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-300"></p>
                    <div className="my-4 text-center text-3xl font-bold mt-8 ">ADD MORE...</div>
                  <label
                    for="countries"
                    class="block mb-2  mt-4 text-sm font-medium text-gray-900 "
                  >
                    Add Category
                  </label>
                 

                  <Select
                    isMulti={true}
                    styles={{
                      menuPortal: (base) => ({
                        zIndex: 999999,
                      }),
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    defaultValue={myDefault}
                    options={categoryOptions}
                    onChange={(newSelection) => {
                      console.log({ newSelection });
                      setBlogCategoryId(newSelection);
                    }}
                  />

                  <div className=" mx-4 w-[50%]">
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      <br />
                      Add trimesters
                    </label>
                    <Select
                      isMulti={true}
                      styles={{
                        menuPortal: (base) => ({
                          zIndex: 999999,
                        }),
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      defaultValue={myDefault}
                      options={trimesterOptions}
                      onChange={(newSelection) => {
                        console.log({ newSelection });
                        // handleChange();

                        setBlogTrisemester(newSelection);
                      }}
                    />

                    <div className=" ">
                      <label
                        for="countries"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Add Tags
                      </label>
                      <Select
                        isMulti={true}
                        styles={{
                          menuPortal: (base) => ({
                            zIndex: 999999,
                          }),
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={myDefault}
                        options={tagOptions}
                        onChange={(newSelection) => {
                          console.log({ newSelection });
                          setBlogTag(newSelection);
                        }}
                      />

                      <div></div>
                    </div>
                    <div className=" ">
                      <label class="block mb-2 text-sm font-medium text-gray-900 ">
                        Add Weeks
                      </label>
                      <Select
                        isMulti={true}
                        styles={{
                          menuPortal: (base) => ({
                            zIndex: 999999,
                          }),
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={myDefault}
                        options={weekOptions}
                        onChange={(newSelection) => {
                          console.log({ newSelection });
                          setBlogWeeks(newSelection);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editBlogMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editBlogMutation.isLoading ? "Editing.." : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    </div>
  );
};

export default BlogEdit;
