import { React, useState, useCallback } from "react";

import AsyncCreatableSelect from "react-select/creatable";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",

  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  height: "80%",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ManageExpertAdd = ({ setShowModal }) => {
  const [expertEnglishName, setExpertEnglishName] = useState();
  const [expertPhone, setExpertPhone] = useState();
  const [expertPassword, setExpertPassword] = useState();
  const [biography, setBiography] = useState();
  const [expertImage, setExpertImage] = useState();
  const [expertTrisemester, setExpertTrisemester] = useState();
  const [expertSpecialtyId, setExpertSpecialtyId] = useState();
  const [dob, setDob] = useState();
  const [profStatment, setProfStatment] = useState();
  const [locationId, setLocationId] = useState();
  const [expertTag, setExpertTag] = useState();
  const [expertWeeks, setExpertWeeks] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [education, setEducation] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState();

  const addProduct = () => {
    setEducation([...education, { title, description }]);
    setTitle("");
    setDescription("");
    console.log(education);
  };

  const [qualify, setQualify] = useState();
  const [langvalue, setLangValue] = useState();
  const [qOptions, setQOptions] = useState([]);
  const [options, setOptions] = useState([
    { value: "amharic", label: "Amharic" },
    { value: "english", label: "English" },
    { value: "oromic", label: "Oromic" },
    { value: "arabic", label: "Arabic" },
  ]);
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addExpertHandler = () => {
    addExpertMutationSubmitHandler();
  };

  const addExpertMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/experts`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.data);
        }
      },
    }
  );

  const addExpertMutationSubmitHandler = async (values) => {
    let formData = new FormData();
    var weekData = [];
    if (expertWeeks) {
      for (var i = 0; i < expertWeeks.length; i++) {
        weekData = [...weekData, expertWeeks[i].id];
        formData.append("weeks[]", weekData[i]);
      }
    }

    var trimesterData = [];
    if (expertTrisemester) {
      for (var i = 0; i < expertTrisemester.length; i++) {
        trimesterData = [...trimesterData, expertTrisemester[i].id];
        formData.append("trimesters[]", trimesterData[i]);
      }
    }

    var tagData = [];
    if (expertTag) {
      for (var i = 0; i < expertTag.length; i++) {
        tagData = [...tagData, expertTag[i].id];
        formData.append("tags[]", tagData[i]);
      }
    }
    var languageData = [];
    for (var i = 0; i < langvalue.length; i++) {
      languageData = [...languageData, langvalue[i].value];
      formData.append("expertLanguages[]", languageData[i]);
    }

    var qualifyData = [];
    for (var i = 0; i < qualify.length; i++) {
      qualifyData = [...qualifyData, qualify[i].value];
      formData.append("expertQualifications[]", qualifyData[i]);
    }

    formData.append("speciality_id", expertSpecialtyId);
    formData.append("name", expertEnglishName);
    formData.append("phone", expertPhone);
    formData.append("password", expertPassword);
    formData.append("dob", dob);
    formData.append("locationId", locationId);
    formData.append("biography", biography);
    formData.append("profStatement", profStatment);
    formData.append("expertEducation[]", JSON.stringify(education));
    formData.append("expertPractices[]", JSON.stringify(education));

    try {
      addExpertMutation.mutate(formData, {
        onSuccess: () => {
          setShowModal(false);
          toast.success("success", {});
          setShowModal(false);
          setExpertWeeks(null)
          setExpertTrisemester(null)
          setExpertTag(null)
          languageData = []
          qualifyData = []
          expertSpecialtyId(null)
          setExpertEnglishName(null)
          setExpertPhone(null)
          setExpertPassword(null)
          setDob(null)
          setLocationId(null)
          setBiography(null)
          setProfStatment(null)
          setEducation([])

        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "speciality add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const SpecialityData = useQuery(
    ["SpecialityDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}admin/specialities`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(specialityData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const specialitySelectHandler = (e) => {
    const id = e.target.value;
    setExpertSpecialtyId(id);
  };

  const LocationData = useQuery(
    ["LocationDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/locations`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(specialityData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const LocationSelectHandler = (e) => {
    const id = e.target.value;
    setLocationId(id);
  };

  const formik = useFormik({
    initialValues: {
      englishName: "",
      phone: "",
      password: "",
      speciality: "",
      location: "",

      dob: "",
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      phone: Yup.string().required("Amharic Name is required"),
      password: Yup.string().required("english Description is required"),

      speciality: Yup.string().required("Speciality is required"),
      location: Yup.string().required("Location is required"),
      dob: Yup.string().required("Date of birth is required"),
    }),
    onSubmit: (values) => addExpertHandler(),
  });

  const handleChange = useCallback(
    (inputValue) => setLangValue(inputValue),
    []
  );
  const handleCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setLangValue(newValue);
    },
    [options]
  );

  const loadOptions = (inputValue, callback) =>
    setTimeout(() => {
      callback(
        options.filter((item) =>
          item.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 3000);

  //Qualifications

  const handleQChange = useCallback((inputValue) => setQualify(inputValue), []);
  const handleQCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setQOptions([...qOptions, newValue]);
      setQualify(newValue);
    },
    [qOptions]
  );

  const loadQOptions = (inputValue, callback) =>
    setTimeout(() => {
      callback(
        qOptions.filter((item) =>
          item.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 3000);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto ">
          {/*content*/}
          <form onSubmit={formik.handleSubmit}>
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Expert</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black   text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className=" text-black  h-6 w-6 text-2xl ">×</span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-row">
                <div className=" px-8 pt-6 pb-8 w-96 ">
                  <div className="">
                    <div className=" ">
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Expert Name
                        </label>
                        <input
                          id="englishName"
                          className="shadow border-black appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExpertEnglishName(e.target.value);
                          }}
                        />
                      </div>
                      {formik.touched.englishName &&
                      formik.errors.englishName ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishName}{" "}
                        </div>
                      ) : null}
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Phone number
                        </label>
                        <input
                          id="phone"
                          type="number"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExpertPhone(e.target.value);
                          }}
                        />{" "}
                        <p
                          class="mt-1 text-sm text-gray-500 "
                          id="file_input_help"
                        >
                          Must start with 2519********.
                        </p>
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Password
                        </label>
                        <input
                          id="password"
                          type="password"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExpertPassword(e.target.value);
                          }}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.password}{" "}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Date of birth
                        </label>
                        <input
                          id="dob"
                          type="date"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setDob(e.target.value);
                          }}
                        />{" "}
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.dob}
                          </div>
                        ) : null}
                      </div>
                      <br />
                      <label
                        for="countries"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Speciality
                      </label>
                      <select
                        id="speciality"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => {
                          formik.handleChange(e);
                          specialitySelectHandler(e);
                        }}
                      >
                        <option selected value={null}>
                          Select Speciality
                        </option>
                        {SpecialityData?.data?.data?.data.map((speciality) => {
                          return (
                            <>
                              <option
                                key={speciality?.id}
                                value={speciality?.id}
                              >
                                {speciality?.speciality}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {formik.touched.speciality && formik.errors.speciality ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.speciality}
                        </div>
                      ) : null}

                      <label
                        for="countries"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Location
                      </label>
                      <select
                        id="location"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => {
                          formik.handleChange(e);
                          LocationSelectHandler(e);
                        }}
                      >
                        <option selected value={null}>
                          Select Location
                        </option>
                        {LocationData?.data?.data?.data.map((location) => {
                          return (
                            <>
                              <option key={location?.id} value={location?.id}>
                                {location?.name?.english}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {formik.touched.location && formik.errors.location ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.location}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="px-8 pt-6 pb-8 w-96">
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Biography
                    </label>
                    <textarea
                      id="biography"
                      className="shadow  h-48 appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setBiography(e.target.value);
                      }}
                    />
                  </div>{" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Professional statement
                    </label>
                    <textarea
                      id="biography"
                      className="shadow h-48 appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setProfStatment(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="px-8 pt-6 pb-8 w-96">
                  {" "}
                  <div className=" ">
                    <div className="App">
                      <br />
                      <p className="text-sm">Type new/select languages</p>
                      <AsyncCreatableSelect
                        isMulti
                        isClearable
                        value={langvalue}
                        options={options}
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        cacheOptions
                        loadOptions={loadOptions}
                      />
                    </div>
                    <div className="App">
                      <br />
                      <p className="text-sm">Add your Qualifications</p>
                      <AsyncCreatableSelect
                        isMulti
                        isClearable
                        value={qualify}
                        options={qOptions}
                        onChange={handleQChange}
                        onCreateOption={handleQCreate}
                        cacheOptions
                        loadOptions={loadQOptions}
                      />
                      <br />
                    </div>

                    <div className=" mt-2 border-black border-[0.5px] flex justify-center ">
                      <Button onClick={handleOpen}>Add Education</Button>
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Add Education
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, fontFamily: "Poppins" }}
                          >
                            <h3>Title</h3>
                            <input
                              placeholder="Title"
                              className="w-full p-2 border-[1px] border-black shadow-sm"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                            <br />
                            <h3>Description</h3>

                            <textarea
                              className="w-full h-32 p-2 shadow-sm"
                              placeholder="Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            <br />
                            <div className="flex justify-end">
                              <button
                                className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleClose}
                              >
                                Done
                              </button>
                              <button
                                className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                onClick={addProduct}
                              >
                                Add
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                            <p>Education:</p>
                            {education?.map((item) => {
                              return (
                                <>
                                  <div
                                    key={item}
                                    className="border bg-slate-100 shadow-lg p-2"
                                  >
                                    <p>title: {item.title}</p>
                                    <p>description: {item.description}</p>
                                    <br />
                                  </div>
                                </>
                              );
                            })}
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={addExpertMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {addExpertMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ManageExpertAdd;
