import React, { useRef, useEffect } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const TimePicker = ({ onTimeChange }) => {
  const timePickerRef = useRef(null);

  useEffect(() => {
    const flatpickrInstance = flatpickr(timePickerRef.current, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      onChange: function(selectedDates, dateStr, instance) {
        // Call the onTimeChange callback with the selected time value
        onTimeChange(dateStr);
      }
    });

    return () => {
      flatpickrInstance.destroy();
    };
  }, [onTimeChange]);

  return (
    <input
      type="text"
      ref={timePickerRef}
      placeholder="Select a time"
    />
  );
};

export default TimePicker;