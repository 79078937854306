import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import React from "react";

const ForumReplyModal = ({ handleClose, style, replyData }) => {
  return (
    <div>
      {" "}
      <Box sx={style}>
        <Typography
         id="modal-modal-title" variant="h6" component="h2">
         Reply Details<br/>
        </Typography>

        <Typography id="modal-modal-description">
          <div className="flex flex-row">
            <div>
              <b>Reply id: </b> {replyData.id}
              <br />
              <b>user id: </b> {replyData.user_id}
              <br />
              <b>Forum answer id: </b> {replyData.forum_answer_id}
              <br />
              <b>created at: </b> {replyData.created_at}
              <br />
              <b>updated at: </b> {replyData.updated_at}
              <br />
              <b>is owner?</b> {replyData.is_owner === true ? "true" : "false"}
              <br />
              <b>reply images:</b>
              <br />
              <ImageList
                className="ml-4  mr-6  border-2 shadow-lg rounded"
                sx={{
                  width: 350,
                  height: 150,
                }}
                cols={4}
                rowHeight={164}
              >
                {replyData?.reply_images?.map((item) => {
                  return (
                    <>
                      <ImageListItem
                       className="p-2" key={item}>
                        <img
                          src={`${item}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.title}
                          loading="lazy"
                        />
                      </ImageListItem>
                    </>
                  );
                })}
              </ImageList>
              <b>Likes: </b> {replyData.likes_count}
              <br />
              <b>has liked?: </b>
              {replyData.has_liked === true ? "true" : "false"}
              <br />
              <b>time: </b> {replyData.diffForHumans}
              <br />
            </div>
            <div>
              {" "}
              <div className="flex flex-col">
                <Typography
                  fontSize={"12px"}
                  gutterBottom
                  sx={{
                    marginTop: "",
                    marginLeft: "20px",
                  }}
                >
                  <b>replier id: </b> {replyData.replier.id}
                  <br />
                  <b>name: </b> {replyData.replier.name}
                  <br />
                  <b>email: </b> {replyData.replier.email}
                  <br />
                  <b>phone: </b> {replyData.replier.phone}
                  <br />
                  <b>otp sent at: </b> {replyData.replier.otp_sent_at}
                  <br />
                  <b>status: </b> {replyData.replier.status}
                  <br />
                  <b>created at: </b> {replyData.replier.created_at}
                  <br />
                  <b>updated at: </b> {replyData.replier.updated_at}
                  <br />
                  <b>role id: </b> {replyData.replier.role_id}
                  <br />
                  <b>role name: </b> {replyData.replier.role.name}
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </Typography>
        <div className="text-gray-700 flex justify-end">
          <button onClick={handleClose}>close</button>
        </div>
      </Box>
    </div>
  );
};

export default ForumReplyModal;
