import { React, useState } from "react";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Button, Typography } from "@material-tailwind/react";
import { Box, Modal } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",

  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  height: "80%",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExercisesAdd = ({ setShowModal }) => {
  const [exerciseEnglishName, setExerciseEnglishName] = useState();
  const [exerciseAmharicName, setExerciseAmharicName] = useState();
  const [exerciseEnglishDescription, setExerciseEnglishDescription] =
    useState();
  const [exerciseAmharicDescription, setExerciseAmharicDescription] =
    useState();
  const [exerciseImage, setExerciseImage] = useState();
  const [exerciseTrisemester, setExerciseTrisemester] = useState(null);
  const [video, setVideo] = useState();
  const [duration, setDuration] = useState();
  const [repitition, setRepitition] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [exercise, setExercise] = useState([]);
  const [caption, setCaption] = useState("");
  const [order, setDescription] = useState();
  const [image, setImage] = useState([]);

  const { token, user } = useAuth();

  const addProduct = () => {
    setExercise([...exercise, { caption, order, image }]);
    setCaption("");
    setDescription("");
    setImage([]);
    console.log(exercise);
  };

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addExerciseHandler = () => {
    addExerciseMutationSubmitHandler();
  };

  const addExerciseMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/exercises`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addExerciseMutationSubmitHandler = async (values) => {
    let formData = new FormData();

    var trimesterData = [];
    for (var i = 0; i < exerciseTrisemester.length; i++) {
      trimesterData = [...trimesterData, exerciseTrisemester[i].id];
      formData.append("trimesters[]", trimesterData[i]);
    }

    var imageData = [];
    for (var i = 0; i < exercise.length; i++) {
      imageData = [...imageData, exercise[i].caption];
      formData.append("images", imageData[i]);
    }

    formData.append("duration", duration);
    formData.append("repetition", repitition);
    // formData.append("images", exercise);
    /* formData.append("trimesters", trimesterData); */

    formData.append("titleEnglish", exerciseEnglishName);
    formData.append("titleAmharic", exerciseAmharicName);

    try {
      addExerciseMutation.mutate(formData, {
        onSuccess: () => {
          setShowModal(false);
          toast.success("success", {});
          setShowModal(false);
          setExerciseTrisemester(null)
          setExercise(null)
          setDuration(null)
          setRepitition(null)
          setExerciseAmharicName(null)
          setExerciseEnglishName(null)
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.data ?? "add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const TrisemesterData = useQuery(
    ["TrisemesterDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/trimesters`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const tempObj = [];
  TrisemesterData?.data?.data?.data.map((item) => {
    tempObj.push({ id: item.id, name: item.name.english });
  });
  const myDefault = [null];
  const trimesterOptions = [...tempObj];

  const formik = useFormik({
    initialValues: {
      englishName: "",
      amharicName: "",
      englishDescription: "",
      amharicDescription: "",
      duration: "",
      images: "",
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("EnglishName is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDescription: Yup.string().required(
        "english Description is required"
      ),
      amharicDescription: Yup.string().required(
        "amharic Description is required"
      ),

      duration: Yup.string().required("Duration is required"),
    }),
    onSubmit: (values) => addExerciseHandler(),
  });

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto ">
          {/*content*/}
          <form onSubmit={formik.handleSubmit}>
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Exercise</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black   text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className=" text-black  h-6 w-6 text-2xl   ">×</span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-row">
                <div className=" px-8 pt-6 pb-8 w-96 ">
                  <div className="">
                    <div className=" ">
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Exercise English Name
                        </label>
                        <input
                          id="englishName"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExerciseEnglishName(e.target.value);
                          }}
                        />
                      </div>
                      {formik.touched.englishName &&
                      formik.errors.englishName ? (
                        <div className="text-[13px] font-medium capitalize text-red-500">
                          {formik.errors.englishName}{" "}
                        </div>
                      ) : null}
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Exercise Amharic Name
                        </label>
                        <input
                          id="amharicName"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExerciseAmharicName(e.target.value);
                          }}
                        />{" "}
                        {formik.touched.amharicName &&
                        formik.errors.amharicName ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.amharicName}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Exercise English Description
                        </label>
                        <textarea
                          id="englishDescription"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);

                            setExerciseEnglishDescription(e.target.value);
                          }}
                        />
                        {formik.touched.englishDescription &&
                        formik.errors.englishDescription ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.englishDescription}{" "}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label className="block text-black text-sm font-base mb-1">
                          Exercise Amharic Description
                        </label>
                        <textarea
                          id="amharicDescription"
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          onChange={(e) => {
                            formik.handleChange(e);
                            setExerciseAmharicDescription(e.target.value);
                          }}
                        />
                        {formik.touched.amharicDescription &&
                        formik.errors.amharicDescription ? (
                          <div className="text-[13px] font-medium capitalize text-red-500">
                            {formik.errors.amharicDescription}{" "}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-8 pt-6 pb-8 w-96">
                  {" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Repitition
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        setRepitition(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Duration
                    </label>
                    <input
                      id="duration"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);

                        setDuration(e.target.value);
                      }}
                    />{" "}
                    {formik.touched.duration && formik.errors.duration ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.duration}
                      </div>
                    ) : null}
                  </div>
                  <div className=" ">
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      <br />
                      Select trimesters
                    </label>
                    <Select
                      isMulti={true}
                      styles={{
                        menuPortal: (base) => ({
                          zIndex: 999999,
                        }),
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      defaultValue={myDefault}
                      options={trimesterOptions}
                      onChange={(newSelection) => {
                        console.log({ newSelection });
                        // handleChange();

                        setExerciseTrisemester(newSelection);
                      }}
                    />
                  </div>
                  <div className=" mt-2  justify-center text-[#636ab1] ">
                 {/*    <Button onClick={handleOpen} className="  text-[#636ab1] ">
                      Add Exercise
                    </Button> */}
                    <Modal open={open} onClose={handleClose}>
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Add Exercise
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2, fontFamily: "Poppins" }}
                        >
                          <h3>Caption</h3>
                          <input
                            placeholder="Title"
                            className="w-full p-2 border-[1px] border-black shadow-sm"
                            value={caption}
                            onChange={(e) => {
                              setCaption(e.target.value);
                            }}
                          />
                          <br />
                          <h3>Order of procedure</h3>
                          <input
                            className="w-22  p-2 shadow-sm"
                            type="number"
                            placeholder="order"
                            value={order}
                            onChange={(e) => setDescription(e.target.value)}
                          />{" "}
                          <p
                            class="mt-1 text-sm text-gray-500 "
                            id="file_input_help"
                          >
                            Duplicates are not allowed.
                          </p>
                          <label
                            class="block mb-2 text-sm pt-4 font-medium text-gray-900 "
                            for="file_input"
                          >
                            Exercise file
                          </label>
                          <input
                            id="video"
                            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none "
                            aria-describedby="file_input_help"
                            onChange={(e) => {
                              formik.handleChange(e);

                              setImage(e.target.files[0]);
                            }}
                            type="file"
                          />
                          <br />
                          <div className="flex justify-end">
                            <button
                              className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={handleClose}
                            >
                              Done
                            </button>
                            <button
                              className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              onClick={addProduct}
                            >
                              Add
                            </button>
                          </div>
                          <br />
                          <br />
                          <br />
                          <p>Exercises added:</p>
                          {exercise?.map((item) => {
                            return (
                              <>
                                <div
                                  key={item}
                                  className="border bg-slate-100 shadow-lg p-2"
                                >
                                  <p>caption: {item.caption}</p>
                                  <p>order: {item.order}</p>
                                  <p>
                                    file:
                                    {item.image?.name}
                                  </p>

                                  <br />
                                </div>
                              </>
                            );
                          })}
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={addExerciseMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {addExerciseMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ExercisesAdd;
