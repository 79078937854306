import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { AiFillCaretDown } from "react-icons/ai";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";

const ForumReplys = ({ answer }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  var counter = 1;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { banswerBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <TableRow>
            <TableCell className="w-[12%]  rounded-full">
              <div>
                <img
                  className="w-fill rounded-full"
                  src={answer?.partisipant?.user_image}
                  loading="lazy"
                />
              </div>
            </TableCell>
            <TableCell>
              <b>{answer?.partisipant?.name}: </b> {answer.body} <br />
              <div className="flex justify-end">
                {answer?.reply_count} Reply
              </div>
              {answer?.partisipant?.created_at}{" "}
            </TableCell>
          </TableRow>
        </TableCell>

        <TableCell align="right"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <Table
                    className="bg-white relative left-40"
                    size="small"
                    aria-label="purchases"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Replys
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {answer.replies.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell
                            className="w-[20%] "
                            component="th"
                            scope="row"
                          >
                            <div className="flex justify-end">
                              <img
                                className=" w-[40%] rounded-full"
                                src={item?.replier?.user_image}
                                loading="lazy"
                              />
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TableRow>
                              {" "}
                              <div className="text-xs">{item.body}</div>
                              <br />
                              {item?.replier?.created_at}
                            </TableRow>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <div className="w-100vh flex justify-end ">
                    <div></div>
                  </div>
                </div>
                <div className="m-4 mt-6 ml-6 w-80"> </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ForumReplys;
