import { ImageList, ImageListItem, Typography } from "@mui/material";
import React from "react";

const ForumAnswerModal = ({ item, handleClose2 }) => {
  return (
    <div>
      <button
        className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        onClick={handleClose2}
      >
        CLOSE
      </button>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="flex flex-row">
          <div>
            <b>answer id</b> {item.id}
            <br />
            <b>user id:</b> {item.user_id}
            <br />
            <b>forum_id: </b>
            {item.forum_id}
            <b>created at: </b>
            {item.updated_at}
            <br />
            <b>updated at: </b>
            {item.updated_at}
            <br />
            <b>is owner?: </b>
            {item.is_owner}
            <br />
            <b>reply: </b>
            {item.reply_count}
            <br />
            <b>likes: </b>
            {item.likes_count}
            <br />
            <b>has liked?: </b>
            {item.has_liked === true ? "true" : "false"}
            <br />
            <b>answer images:</b>
            <br />
            <ImageList
              className="ml-4  mr-6  border-2 shadow-lg rounded"
              sx={{
                width: 450,
                height: 150,
              }}
              cols={4}
              rowHeight={164}
            >
              {item?.answer_images?.map((item) => {
                return (
                  <>
                    <ImageListItem className="p-2 bg-white" key={item}>
                      <img
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </>
                );
              })}
            </ImageList>
            <br />
            <b>time: </b>
            {item.diffForHumans}
            <br />
          </div>
          <div>
            <Typography
              fontSize={"12px"}
              sx={{
                marginTop: "0px",
                marginLeft: "10px",
              }}
            >
              <b>participant id</b> {item.partisipant.id}
              <br />
              <b>name</b> {item.partisipant.name}
              <br />
              <b>email</b> {item.partisipant.email}
              <br />
              <b>phone</b> {item.partisipant.phone}
              <br />
              <b>otp sent at</b> {item.partisipant.otp_sent_at}
              <br />
              <b>status</b> {item.partisipant.status}
              <br />
              <b>created at</b> {item.partisipant.created_at}
              <br />
              <b>updated at</b> {item.partisipant.updated_at}
              <br />
              <b>role id</b> {item.partisipant.role_id}
              <br />
              <b>role name</b> {item.partisipant.role.name}
            </Typography>
          </div>
        </div>
      </Typography>
    </div>
  );
};

export default ForumAnswerModal;
