import React, { useState } from "react";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AppointmentDelete from "./AppointmentDelete";
import AppointmentEdit from "./AppointmentEdit";
const AppointmentTable = ({
  appointment,
  id,
  setDeleteAppointmentId,
  setEditAppointmentId,
  setViewAppointmentId,
  setEditAppId,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();
  return (
    <tr key={id} class="bg-white border-b">
      <td class="px-6 py-4">
        <p>{appointment?.expert_schedule?.expert?.name}</p>
      </td>
      <td class="px-6 py-4">
        <p>{appointment?.client?.name}</p>
      </td>
      <td class="px-6 py-4">
        <p>{appointment?.expert_schedule?.start_time}</p>
      </td>
      <td class="px-6 py-4">
        <p>{appointment?.expert_schedule?.end_time}</p>
      </td>
      <td class="px-6 py-4">
        <p>{appointment?.status == 0 ? "Not Active" : "Active"  }</p>
      </td>

      <td class=" ">
        
          <div>
            {showEditModal ? (
              <>
                <AppointmentEdit
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  appointment={appointment}
                  id={id}
                  setEditAppointmentId={setEditAppointmentId}
                  setEditAppId={setEditAppId}
                />
              </>
            ) : null}
          </div>
          <div className="">
            <button
              onClick={() => {
                setShowEditModal(true);
                setEditAppointmentId(appointment?.id);
              }}
            >
              <RiEdit2Fill className="text-gray-500 mr-2 text-2xl" />
            </button>
           
          </div>
        
      </td>
    </tr>
  );
};

export default AppointmentTable;
