import React from "react";

const ReportManagmentCommunity = () => {
  return (
    <div>
      <h2 class="mb-4 ml-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl ">
        Community Report Managment
      </h2>
    </div>
  );
};

export default ReportManagmentCommunity;
