import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { AiFillCaretDown } from "react-icons/ai";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  ImageList,
  ImageListItem,
} from "@mui/material";
import ForumReplys from "./ForumReplys";
import { useNavigate } from "react-router-dom";

export default function ForumTable({ forum }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const open1 = Boolean(anchorEl);
  var counter = 1;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <TableRow>
            <b>Question: </b> {forum.body}{" "}
            <button
              className="text-[#636ab1]"
              onClick={() => {
                navigate(`/forum/${forum.id}`);
              }}
            >
              <u>Details</u>
            </button>
            <div className="w-[80%] text-[#636ab1] flex justify-start p-1 ">
              <br />
              {forum?.tags.map((forum, id) => (
                <>#{forum.name} </>
              ))}
            </div>
          </TableRow>
          <div className="w-100vh flex justify-end ">
            <TableRow>
              <div className="w-fill flex justify-start p-2 text-xs rounded-sm  bg-blue-100">
                <div>
                  {" "}
                  <img
                    className="p-2 w-12 rounded-full"
                    src={forum?.creator?.user_image}
                    loading="lazy"
                  />
                </div>
                <div className="pt-2">
                  {forum?.creator?.name}
                  <br />
                  {forum?.creator?.created_at}
                </div>
              </div>
            </TableRow>
          </div>
        </TableCell>

        <TableCell align="center">{forum?.answer_count} answer(s)</TableCell>
        <TableCell align="right">{forum?.category?.name?.english}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <div className="flex flex-row relative left-10 ">
                <div className="flex flex-col">
                  <Table
                    className="bg-gray-100 text-sm"
                    size="small"
                    aria-label="purchases"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {forum.answers ? "Answers" : "No answers"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forum.answers.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            <ForumReplys answer={item} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="m-4 mt-6 ml-6 w-80"> </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
