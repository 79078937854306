import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SpecialityEdit = ({
  showEditModal,
  setShowEditModal,
  speciality,
  id,
  setEditSpecialityId,
  setEditSpecId,
}) => {
  const [specialityEnglish, setSpecialityEnglish] = useState(
    speciality?.speciality
  );
  const [description, setDescription] = useState(speciality?.description);
  const [editSpeciality, setEditSpeciality] = useState();
  const { token, user } = useAuth();

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editSpecialityMutation = useMutation(
    async (editSpeciality) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/specialities/${editSpeciality?.id}`,
          formData,
        config
      )
  );
  const editSpecialityHandler = async (newData) => {
    console.log({ speciality });

    try {
      formData.append("speciality", specialityEnglish);
      formData.append("_method", "patch");

      editSpecialityMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            toast.success("Edit success!");
            setShowEditModal(false);
            setEditSpecId(null);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      speciality: speciality?.speciality,
    },
    validationSchema: Yup.object({
      speciality: Yup.string().required("Speciality is required"),
    }),
    onSubmit: (values) => {
      editSpecialityHandler(speciality?.id);
      setEditSpeciality(speciality?.id);
      setEditSpecialityId(1);
    },
  });

  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit Speciality</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <label className="block text-black text-sm font-base mb-1">
                    Speciality
                  </label>
                  <input
                    name="speciality"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={speciality?.speciality}
                    onChange={(e) => {
                      formik.handleChange(e);

                      setSpecialityEnglish(e.target.value);
                    }}
                  />
                  {formik.touched.speciality && formik.errors.speciality ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.speciality}
                    </div>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editSpecialityMutation.isLoading}
                    className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editSpecialityMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      <ToastContainer />
    </div>
  );
};

export default SpecialityEdit;
