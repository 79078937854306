import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import BlogTagDelete from "./BlogTagDelete";
import { toast } from "react-toastify";

const BlogTagTable = ({ item, id, setDeleteBlogTagId, blogId }) => {
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <div
        className="pb-4 bg-gray-100 shadow-lg border-t-4 mt-6 p-1 border-black
                           "
      >
        <div className="flex flex-row ">
          <div className="flex flex-col border ">
            <div className="w-[100%]">
              <b>English Name:</b>
              {"  "} {item?.name?.english}
            </div>
            <div className="w-[100%]">
              <b>Amharic Name:</b>
              {"  "} {item?.name?.amharic}
            </div>{" "}
          </div>
          <div className="w-[10%]  flex justify-end pr-4">
            {showDeleteTagModal ? (
              <>
                <BlogTagDelete
                blogId={blogId}
                  blogTag={item}
                  setShowDeleteTagModal={setShowDeleteTagModal}
                  setDeleteBlogTagId={setDeleteBlogTagId}
                />
              </>
            ) : null}
            <button
              className=""
              onClick={() => {
                setShowDeleteTagModal(true);
                
              }}
            >
              <RiDeleteBin2Fill className="text-gray-500 text-2xl" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogTagTable;
