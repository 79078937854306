import React, { useState } from "react";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../../context/auth";
import Select from "react-select";
import Editor from "../../../../components/Editor";
import TimePicker from "../../../../components/TimePicker";

const AddWorkingHours = ({
  WorkingDayId,
  setShowAddWorkingHourModal,

}) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addWorkingHourHandler = () => {
    addWorkingHourMutationSubmitHandler();
  };

  const addWorkingHourMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/working-hours`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addWorkingHourMutationSubmitHandler = async (values) => {
    let formData = new FormData();

    formData.append("working_day_id", WorkingDayId);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);

    try {
      addWorkingHourMutation.mutate(formData, {
        onSuccess: () => {
          setShowAddWorkingHourModal(false);
          toast.success("Add success!");
          window.location.reload()
         
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "WorkingHour add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-[90%] h-[55%]">
          {/*content*/}
          <form className=" px-8 pt-6 pb-8 w-full">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Working Hours</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowAddWorkingHourModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-row w-[100%] ">
                <div className="grid grid-cols-2 w-[100%] gap-6">
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Start Time
                    </label>
                    <TimePicker onTimeChange={setStartTime} />
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      End Time
                    </label>
                    <TimePicker onTimeChange={setEndTime} />
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <div className="flex justify-start flex-col w-1/2">

                  <p className="mb-2 ">24 Hour Format</p>
                  <p>Start Time: {startTime}</p>
                  <p>End Time: {endTime}</p>
                </div>

                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowAddWorkingHourModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={addWorkingHourMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={() => {
                    addWorkingHourHandler();
                  }}
                >
                  {addWorkingHourMutation.isLoading
                    ? "saving.."
                    : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-10 fixed inset-0 z-40 bg-black"></div>
      <ToastContainer />
    </>
  );
};

export default AddWorkingHours;
