import React, { useState } from "react";
import axios from "axios";
import { GiToken } from "react-icons/gi";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/auth";
import Select from "react-select";
import Editor from "../../../components/Editor";

const AddTip = ({ setShowModal }) => {
  const [tipEnglishName, setTipEnglishName] = useState();
  const [tipAmharicName, setTipAmharicName] = useState();
  const [tipEnglishDescription, setTipEnglishDescription] = useState();
  const [tipAmharicDescription, setTipAmharicDescription] = useState();
  const [tipImage, setTipImage] = useState();

  const [categoryId, setCategoryId] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  const addTipHandler = () => {
    addTipMutationSubmitHandler();
  };

  const addTipMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}admin/tips`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const addTipMutationSubmitHandler = async (values) => {
    let formData = new FormData();

    formData.append("category_id", categoryId);
    formData.append("titleEnglish", tipEnglishName);
    formData.append("titleAmharic", tipAmharicName);
    formData.append("descriptionEnglish", tipEnglishDescription);
    formData.append("descriptionAmharic", tipAmharicDescription);
    formData.append("tip_photo", tipImage);
    try {
      addTipMutation.mutate(formData, {
        onSuccess: () => {
          setShowModal(false);
          toast.success("Add success!");
          setShowModal(false);
          setCategoryId(null)
          setTipEnglishName(null)
          setTipAmharicName(null)
          setTipEnglishDescription(null)
          setTipAmharicDescription(null)
          setTipImage(null)
        },
        onError: (err) => {
          console.log({ err });
          toast.error(err?.response?.data?.message ?? "catTip add failed");
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const CategoryData = useQuery(
    ["CategoryDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/categories`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  const categorySelectHandler = (e) => {
    const id = e.target.value;
    setCategoryId(id);
    console.log(id);
  };

  const formik = useFormik({
    initialValues: {
      englishName: "",
      amharicName: "",
      englishDescription: "",
      amharicDescription: "",
      images: "",
      category: "",
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("English Name is required"),
      amharicName: Yup.string().required("Amharic Name is required"),
      englishDescription: Yup.string().required(
        "English Description is required"
      ),
      amharicDescription: Yup.string().required(
        "Amharic Description is required"
      ),
      images: Yup.string().required("image is required"),
      category: Yup.string().required("Category is required"),
    }),
    onSubmit: (values) => {
      addTipHandler();
    },
  });
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-[90%] h-[95%]">
          {/*content*/}
          <form
            className=" px-8 pt-6 pb-8 w-full"
            onSubmit={formik.handleSubmit}
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Tip</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex flex-row w-[100%] ">
                <div className="felx flex-row w-[50%] p-6">
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      English Tip Name
                    </label>
                    <input
                      id="englishName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setTipEnglishName(e.target.value);
                      }}
                    />
                    {formik.touched.englishName && formik.errors.englishName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.englishName}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      Amharic Tip Name
                    </label>
                    <input
                      id="amharicName"
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setTipAmharicName(e.target.value);
                      }}
                    />
                    {formik.touched.amharicName && formik.errors.amharicName ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicName}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Category
                    </label>
                    <select
                      id="category"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => {
                        formik.handleChange(e);

                        categorySelectHandler(e);
                      }}
                    >
                      <option selected value="">
                        Select Category English Name
                      </option>
                      {CategoryData?.data?.data?.data.map((category) => {
                        return (
                          <>
                            <option key={category?.id} value={category?.id}>
                              {category?.name?.english}
                            </option>
                          </>
                        );
                      })}
                    </select>
                         {formik.touched.category && formik.errors.category ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.category}
                      </div>
                    ) : null} 
                  </div>
                  <label
                    class="block mb-2 text-sm font-medium text-gray-900 mt-6 "
                    for="file_input"
                  >
                    Tip Image file
                  </label>
                  <input
                    id="images"
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none "
                    aria-describedby="file_input_help"
                    onChange={(e) => {
                      formik.handleChange(e);

                      setTipImage(e.target.files[0]);
                    }}
                    type="file"
                  />
                    {formik.touched.images && formik.errors.images ? (
                            <div className="text-[13px] font-medium capitalize text-red-500">
                              {formik.errors.images}{" "}
                            </div>
                          ) : null} 
                  <p class="mt-1 text-sm text-gray-500 " id="file_input_help">
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                </div>
                <div>
                  {" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1">
                      English Tip Description
                    </label>
                    <Editor
                      id="englishDescription"
                      value={formik.values.englishDescription}
                      setValue={(val) => {
                        setTipEnglishDescription(val);
                        formik.setFieldValue("englishDescription", val);
                      }}
                    />{" "}
                    {formik.touched.englishDescription &&
                    formik.errors.englishDescription ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.englishDescription}
                      </div>
                    ) : null}
                  </div>{" "}
                  <div>
                    <label className="block text-black text-sm font-base mb-1 mt-6">
                      Amharic Tip Description
                    </label>
                    <Editor
                      id="amharicDescription"
                      value={formik.values.amharicDescription}
                      setValue={(val) => {
                        setTipAmharicDescription(val);
                        formik.setFieldValue("amharicDescription", val);
                      }}
                    />{" "}
                    {formik.touched.amharicDescription &&
                    formik.errors.amharicDescription ? (
                      <div className="text-[13px] font-medium capitalize text-red-500">
                        {formik.errors.amharicDescription}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={addTipMutation.isLoading}
                  className="bg-[#636ab1] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  {addTipMutation.isLoading ? "saving.." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <ToastContainer />
    </>
  );
};

export default AddTip;
