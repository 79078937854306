import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/auth";
import { ImageList } from "@mui/material";

const EmoticonEdit = ({
  showEditModal,
  setShowEditModal,
  emoticon,
  id,
  setEditEmoId,

  setEditEmoticonId,
}) => {
  const [englishName, setEnglishName] = useState(emoticon?.name);

  const [englishDescription, setEnglishDescription] = useState(
    emoticon?.description
  );
  const [emoticonImage, setEmoticonImage] = useState(emoticon?.emoticon_image);
  const [order, setOrder] = useState(emoticon?.order);

  const [editEmoticon, setEditEmoticon] = useState();

  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const config = {
    headers,
  };
  const params = new URLSearchParams();
  let formData = new FormData();

  const editEmoticonMutation = useMutation(
    async (editEmoticon) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `admin/emoticons/${editEmoticon?.id}`,
        formData,
        config
      )
  );

  const editEmoticonHandler = async (newData) => {
    console.log({ englishName });

    try {
      formData.append("name", englishName);
      formData.append("description", englishDescription);
      formData.append("order", order);
      formData.append("_method", "patch");

      editEmoticonMutation.mutate(
        {
          id: newData,
        },
        {
          onSuccess: () => {
            setShowEditModal(false);
            setEditEmoId(null);
            toast.success("success");
            setShowEditModal(false);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      englishName: emoticon?.name,

      englishDescription: emoticon?.description,
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("Name is required"),

      englishDescription: Yup.string().required(" Description is required"),
    }),
    onSubmit: (values) => {
      editEmoticonHandler(emoticon?.id);
      setEditEmoticon(emoticon?.id);
      setEditEmoId(1);
    },
  });
  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <form
              className=" px-8 pt-6 pb-8 w-full"
              onSubmit={formik.handleSubmit}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Edit emoticon</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowEditModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <label className="block text-black text-sm font-base mb-1">
                    Name
                  </label>
                  <input
                    id="englishName"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={emoticon?.name}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setEnglishName(e.target.value);
                    }}
                  />
                  {formik.touched.englishName && formik.errors.englishName ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.englishName}{" "}
                    </div>
                  ) : null}

                  <label className="block text-black text-sm font-base mb-1">
                    Description
                  </label>
                  <input
                    id="englishDescription"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={emoticon?.description}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setEnglishDescription(e.target.value);
                    }}
                  />
                  {formik.touched.englishDescription &&
                  formik.errors.englishDescription ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.englishDescription}{" "}
                    </div>
                  ) : null}
                  <label className="block text-black text-sm font-base mb-1">
                    Order
                  </label>
                  <input
                    id="order"
                    type="number"
                    className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                    defaultValue={emoticon?.order}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setOrder(e.target.value);
                    }}
                  />
                  {formik.touched.order && formik.errors.order ? (
                    <div className="text-[13px] font-medium capitalize text-red-500">
                      {formik.errors.order}{" "}
                    </div>
                  ) : null}
                  <label
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    for="file_input"
                  >
                    Emoticon Image file
                  </label>
                  <ImageList
                    className="ml-4  mr-6  border-2 shadow-lg rounded"
                    sx={{ width: 550, height: 250 }}
                    cols={3}
                    rowHeight={200}
                  >
                    {emoticon?.emoticon_image?.map((item) => {
                      return (
                        <>
                          <div key={item}>
                            <img
                              src={`${item}?w=164&h=164&fit=crop&auto=format`}
                              srcSet={`${item}?w=164&h=164&fit=crop&auto=format&pr=2 2x`}
                              alt="image"
                              loading="lazy"
                            />
                          </div>
                        </>
                      );
                    })}
                  </ImageList>
                  <label className="block text-black text-sm font-base mb-1">
                    Add more Images
                  </label>
                  <input
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    onChange={(e) => {
                      setEmoticonImage(e.target.files[0]);
                    }}
                    type="file"
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={editEmoticonMutation.isLoading}
                    className="bg-[#636ab1]  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {editEmoticonMutation.isLoading
                      ? "Editing.."
                      : "Save Changes"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    </div>
  );
};

export default EmoticonEdit;
