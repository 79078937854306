import {
  CssBaseline,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineArrowBack } from "react-icons/md";
import { Bars } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/auth";
import { shadows } from "@mui/system";
import ReactQuill from "react-quill";

import ExerciseMediaAdd from "./ExerciseMediaAdd";
import ExerciseMediaTable from "./ExerciseMediaTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const ExerciseMedia = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteExerciseMediaId, setDeleteExerciseMediaId] = useState(false);
  const [editExerciseMediaId, setEditExerciseMediaId] = useState(false);

  const [viewExerciseMediaId, setViewExerciseMediaId] = useState(false);
  const [editMedId, setEditMedId] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ExerciseMediaData = useQuery(
    ["ExerciseMediaDataApi",showModal, deleteExerciseMediaId, editExerciseMediaId, editMedId],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}admin/exercise-media?exerciseId=${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic)
        if (deleteExerciseMediaId == 1) {
          toast.success("Delete Success");
        }
        setDeleteExerciseMediaId(false);
        setEditMedId(false)
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ExerciseMediaData]);

  return (
    <>
      {ExerciseMediaData.error ? (
        <div className="text-red-700 text-4xl">Error!</div>
      ) : null}
      {ExerciseMediaData.isLoading ? (
        <div className="h-44 flex items-center justify-center min-h-0">
          <Bars
            height="40"
            width="40"
            color="#636ab1"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <React.Fragment>
          <CssBaseline />
          <Container sx={{}} maxWidth="100vh">
            <Box
              sx={{
                bgcolor: "#f1f1f1",

                height: "fill",
                margin: "0px",
                padding: "0px",
              }}
            >
              <button>
                <MdOutlineArrowBack
                  onClick={() => navigate(`/exercises`)}
                  className="text-[#636ab1] text-4xl"
                />
              </button>
            </Box>
            <div>
              <h2 class="mb-4 ml-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl">
                Exercise Media
              </h2>
              {/* modal */}
              <div className="flex justify-end p-2">
                <button
                  className=" block text-white bg-[#636ab1] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-10 py-2.5 text-center"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Add Exercise Media
                </button>
              </div>
              {showModal ? (
                <ExerciseMediaAdd setShowModal={setShowModal} id={id} />
              ) : null}
              {ExerciseMediaData.error ? (
                <div className="text-red-700 text-4xl">Error!</div>
              ) : null}
              {ExerciseMediaData.isLoading ? (
                <div className="h-44 flex items-center justify-center min-h-0">
                  <Bars
                    height="40"
                    width="40"
                    color="#636ab1"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <div>
                  <div class="relative shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          <th class="px-6 py-3"></th>
                       
                          <th class=" py-3 flex justify-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ExerciseMediaData?.data?.data?.data.map(
                          (exercise, id) => (
                            <ExerciseMediaTable
                              exercise={exercise}
                              id={id}
                              setDeleteExerciseMediaId={
                                setDeleteExerciseMediaId
                              }
                              setEditExerciseMediaId={setEditExerciseMediaId}
                              setEditMedId={setEditMedId}
                              setViewExerciseMediaId={setViewExerciseMediaId}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}{" "}
              <ToastContainer />
            </div>
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default ExerciseMedia;
